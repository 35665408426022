import React from "react";

        class Talleres extends React.Component  {
  render() {
    return (
  <>
      <div class="table-responsive-xl" >
      <table class="table table-bordered text-center">
  <thead>
      <tr>
          <th colspan="4" class="THC bg-info text-light text-center">Talleres</th>
      </tr>
    <tr>
      <th scope="col" class="TC table-success" >Titulo</th>
      <th scope="col" class="TC table-info">Plataforma</th>
      <th scope="col" class="TC table-danger">Fecha 1</th>
      <th scope="col" class="TC table-success">Descripción</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>EVENET</td>
      <td>ZOOM</td>
      <td>Transmitiendo ahora en vivo</td>
      <td>El potencial de Evenet para tus eventos</td>
    </tr>
    <tr>
      <td>Hello Survey</td>
      <td>GOOGLE MEET</td>
      <td>Transmitiendo ahora en vivo</td>
      <td>¿Qué es Hello Survey y para qué sirve?</td>
    </tr>
    <tr>
    <td>Interactive Assitant</td>
    <td>SKYPE</td>
      <td>Transmitiendo ahora en vivo</td>
      <td>¿Qué es la herramienta IA y para qué sirve?</td>
    </tr>
    <tr>
    <td>Costum Digital Games</td>
    <td>ZOOM</td>
      <td>Transmitiendo ahora en vivo</td>
      <td>¿Qué es CDGames y cómo ayuda a mi marca?</td>
    </tr>
   
    <tr>
    <td>Un poco de historia</td>
    <td>SKYPE</td>
      <td>Transmitiendo ahora en vivo</td>
      <td>Video explicativo acerca de la historia del internet</td>
    </tr>
  </tbody>
</table>

      </div>
   
      

</>
    );
  }
}
 
 
export default Talleres;
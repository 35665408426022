import React from 'react';
// import { Redirect } from 'react-router-dom';
import Footer from './Footer';
// import CarruselPagina from './CarruselPagina';
import Acomercial from './Acomercial';
import Carrusel from './Carrusel';
import '../Component/styles/Home.css';

import { Card, CardBody, Row, Col} from 'reactstrap';

class Inicio extends React.Component {



  render() {

  
  


    return (

      <>
        <main role="main" className="flex-shrink-0 mt-12">
          <div className="container "> 
            <div className="row">
            </div>
            <div className="align-items-center">
            <Row>
          <Col sm="3" >
            <Card>
              <CardBody>
                <Acomercial />
              </CardBody>
            </Card>
          </Col>

          <Col sm="9" >
            <Card >
              {/* <CarruselPagina /> */}
            </Card>
          </Col>
          </Row>
          <Carrusel class="col-12" />
            </div>
          </div>
        </main>
          <Footer />
      </>

    )

  }
}

export default Inicio;
import React, { useState } from 'react';
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from 'reactstrap';
import DropdownButton from 'react-bootstrap/DropdownButton';
import PropTypes from 'prop-types';
import '../Component/styles/Menu.css';

function Example({ direction, ...args }) {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [dropdownOpen1, setDropdownOpen1] = useState(false);
    const [dropdownOpen2, setDropdownOpen2] = useState(false);
    const [dropdownOpen3, setDropdownOpen3] = useState(false);
    const [dropdownOpen4, setDropdownOpen4] = useState(false);
    const [dropdownOpen5, setDropdownOpen5] = useState(false);
    const [dropdownOpen6, setDropdownOpen6] = useState(false);
    const [dropdownOpen7, setDropdownOpen7] = useState(false);
    const [dropdownOpen8, setDropdownOpen8] = useState(false);

    const toggle = () => setDropdownOpen((prevState) => !prevState);
    const toggle3 = () => setDropdownOpen1((prevState) => !prevState);
    const toggle4 = () => setDropdownOpen2((prevState) => !prevState);
    const toggle5 = () => setDropdownOpen3((prevState) => !prevState);
    const toggle6 = () => setDropdownOpen4((prevState) => !prevState);
    const toggle7 = () => setDropdownOpen5((prevState) => !prevState);
    const toggle8 = () => setDropdownOpen6((prevState) => !prevState);
    const toggle9 = () => setDropdownOpen7((prevState) => !prevState);
    const toggle10 = () => setDropdownOpen8((prevState) => !prevState);

    return (
        <div className="d-flex p-0">
            <Dropdown isOpen={dropdownOpen} toggle={toggle} direction={direction} >
                {/* <li class="nav-item py-1"><a href="/SistemasAspel" class="logo btn btnmenuPrincipal btnmenuPrincipal2"> Sistemas Siggo Aspel </a></li> */}
                <DropdownToggle caret style={{ backgroundColor: 'white', color: '#0F2666' }}>Sistemas Siigo Aspel</DropdownToggle>
                <DropdownMenu {...args}>
                    {/* <DropdownItem header>Header</DropdownItem> */}
                    {/* Menu Administración */}
                    <Dropdown isOpen={dropdownOpen1} toggle={toggle3} direction="right">
                        {/* <li class="nav-item py-1"><a href="/SistemasAspel" class="logo btn btnmenuPrincipal btnmenuPrincipal2"> Sistemas Siggo Aspel </a></li> */}
                        <DropdownToggle caret header className="MenuSiggoAspel">Administración</DropdownToggle>
                        <DropdownMenu {...args}>
                            {/* <DropdownItem header>Header</DropdownItem>
                            <DropdownMenu {...args}>
                            </DropdownMenu> */}
                            <DropdownItem href="SistemasAspel#SAE">SAE</DropdownItem>
                            <DropdownItem href="SistemasAspel">ADM</DropdownItem>
                            <DropdownItem href="SistemasAspel">Siggo Nube</DropdownItem>
                            <DropdownItem divider />
                            <DropdownItem disabled>#OCAconsultoria</DropdownItem>
                            {/* <DropdownItem>Foo Action</DropdownItem>
                            <DropdownItem>Bar Action</DropdownItem>
                            <DropdownItem>Quo Action</DropdownItem> */}
                        </DropdownMenu>
                    </Dropdown>

                    {/* Menu Contabilidad*/}
                    <Dropdown isOpen={dropdownOpen2} toggle={toggle4} direction="right" className="py-2">
                        {/* <li class="nav-item py-1"><a href="/SistemasAspel" class="logo btn btnmenuPrincipal btnmenuPrincipal2"> Sistemas Siggo Aspel </a></li> */}
                        <DropdownToggle caret header className="MenuSiggoAspel">Contabilidad</DropdownToggle>
                        <DropdownMenu {...args}>
                            {/* <DropdownItem header>Header</DropdownItem> */}
                            {/* <DropdownMenu {...args}>
                            </DropdownMenu> */}
                            <DropdownItem href="SistemasAspel#COI">COI</DropdownItem>
                            <DropdownItem divider />
                            <DropdownItem disabled>#ocaconsultoria</DropdownItem>
                            {/* <DropdownItem>Foo Action</DropdownItem>
                            <DropdownItem>Bar Action</DropdownItem>
                            <DropdownItem>Quo Action</DropdownItem> */}
                        </DropdownMenu>
                    </Dropdown>

                    {/* Menu Nomina*/}
                    <Dropdown isOpen={dropdownOpen3} toggle={toggle5} direction="right" className="py-2">
                        {/* <li class="nav-item py-1"><a href="/SistemasAspel" class="logo btn btnmenuPrincipal btnmenuPrincipal2"> Sistemas Siggo Aspel </a></li> */}
                        <DropdownToggle caret header className="MenuSiggoAspel">Nómina y RH</DropdownToggle>
                        <DropdownMenu {...args}>
                            {/* <DropdownItem header>Header</DropdownItem> */}
                            {/* <DropdownMenu {...args}>
                            </DropdownMenu> */}
                            <DropdownItem href="SistemasAspel#NOI">NOI</DropdownItem>
                            <DropdownItem href="SistemasAspel">NOI Asistente</DropdownItem>
                            <DropdownItem divider />
                            <DropdownItem disabled>#ocaconsultoria</DropdownItem>
                            {/* <DropdownItem>Foo Action</DropdownItem>
                            <DropdownItem>Bar Action</DropdownItem>
                            <DropdownItem>Quo Action</DropdownItem> */}
                        </DropdownMenu>
                    </Dropdown>

                    {/* Menu Facturación*/}
                    <Dropdown isOpen={dropdownOpen4} toggle={toggle6} direction="right" className="py-2">
                        {/* <li class="nav-item py-1"><a href="/SistemasAspel" class="logo btn btnmenuPrincipal btnmenuPrincipal2"> Sistemas Siggo Aspel </a></li> */}
                        <DropdownToggle caret header className="MenuSiggoAspel">Facturación</DropdownToggle>
                        <DropdownMenu {...args}>
                            {/* <DropdownItem header>Header</DropdownItem> */}
                            {/* <DropdownMenu {...args}>
                            </DropdownMenu> */}
                            <DropdownItem href="SistemasAspel#SAE">SAE</DropdownItem>
                            <DropdownItem href="SistemasAspel">ADM</DropdownItem>
                            <DropdownItem href="SistemasAspel#FACTURE">FACTURE</DropdownItem>
                            <DropdownItem divider />
                            <DropdownItem disabled>#ocaconsultoria</DropdownItem>
                            {/* <DropdownItem>Foo Action</DropdownItem>
                            <DropdownItem>Bar Action</DropdownItem>
                            <DropdownItem>Quo Action</DropdownItem> */}
                        </DropdownMenu>
                    </Dropdown>

                    {/* Menu Facturación*/}
                    <Dropdown isOpen={dropdownOpen5} toggle={toggle7} direction="right" className="py-2">
                        {/* <li class="nav-item py-1"><a href="/SistemasAspel" class="logo btn btnmenuPrincipal btnmenuPrincipal2"> Sistemas Siggo Aspel </a></li> */}
                        <DropdownToggle caret header className="MenuSiggoAspel">Punto de Venta</DropdownToggle>
                        <DropdownMenu {...args}>
                            {/* <DropdownItem header>Header</DropdownItem> */}
                            {/* <DropdownMenu {...args}>
                            </DropdownMenu> */}
                            <DropdownItem href="SistemasAspel#CAJA">CAJA</DropdownItem>
                            <DropdownItem href="SistemasAspel">ADM TIENDA</DropdownItem>
                            <DropdownItem divider />
                            <DropdownItem disabled>#ocaconsultoria</DropdownItem>
                            {/* <DropdownItem>Foo Action</DropdownItem>
                            <DropdownItem>Bar Action</DropdownItem>
                            <DropdownItem>Quo Action</DropdownItem> */}
                        </DropdownMenu>
                    </Dropdown>

                    {/* Menu Facturación*/}
                    <Dropdown isOpen={dropdownOpen6} toggle={toggle8} direction="right" className="py-2">
                        {/* <li class="nav-item py-1"><a href="/SistemasAspel" class="logo btn btnmenuPrincipal btnmenuPrincipal2"> Sistemas Siggo Aspel </a></li> */}
                        <DropdownToggle caret header className="MenuSiggoAspel" href="SistemasAspel#COI">Manufactura-PROD</DropdownToggle>
                        <DropdownMenu {...args}>
                            {/* <DropdownItem header>Header</DropdownItem> */}
                            {/* <DropdownMenu {...args}>
                            </DropdownMenu> */}

                            <DropdownItem divider />
                            <DropdownItem disabled>#ocaconsultoria</DropdownItem>
                            {/* <DropdownItem>Foo Action</DropdownItem>
                            <DropdownItem>Bar Action</DropdownItem>
                            <DropdownItem>Quo Action</DropdownItem> */}
                        </DropdownMenu>
                    </Dropdown>

                     {/* Menu F*/}
                     <Dropdown isOpen={dropdownOpen7} toggle={toggle9} direction="right" className="py-2">
                        {/* <li class="nav-item py-1"><a href="/SistemasAspel" class="logo btn btnmenuPrincipal btnmenuPrincipal2"> Sistemas Siggo Aspel </a></li> */}
                        <DropdownToggle caret header className="MenuSiggoAspel">Tesoreria-BANCO    </DropdownToggle>
                        <DropdownMenu {...args}>
                            {/* <DropdownItem header>Header</DropdownItem> */}
                            {/* <DropdownMenu {...args}>
                            </DropdownMenu> */}
                            
                            <DropdownItem divider />
                            <DropdownItem disabled>#ocaconsultoria</DropdownItem>
                            {/* <DropdownItem>Foo Action</DropdownItem>
                            <DropdownItem>Bar Action</DropdownItem>
                            <DropdownItem>Quo Action</DropdownItem> */}
                        </DropdownMenu>
                    </Dropdown>


                    {/* <DropdownMenu {...args}>
                    </DropdownMenu> */}
                    {/* <DropdownItem>Some Action</DropdownItem>
                    <DropdownItem text>Dropdown Item Text</DropdownItem>
                    <DropdownItem disabled>Action (disabled)</DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem>Foo Action</DropdownItem>
                    <DropdownItem>Bar Action</DropdownItem> */}
                    {/* <DropdownItem>Quo Action</DropdownItem> */}
                </DropdownMenu>
            </Dropdown>
        </div>
    );
}

Example.propTypes = {
    direction: PropTypes.string,
};

export default Example;
import React from 'react';
import '../Component/styles/Slider.css';
import a from '../IMG/Slide/1.png';
import b from '../IMG/Slide/2.png';
import c from '../IMG/Slide/3.png';


 
class Slider extends React.Component {
 
  render() {
 
   return (

    <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
  <ol class="carousel-indicators">
    <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
    <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
    <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
  </ol>
  <div class="carousel-inner">
    <div class="carousel-item active">
      <img src={a} class="d-block YII " alt="1"/>
    </div>
    <div class="carousel-item">
      <img src={b} class="d-block YII" alt="2"/>
    </div>
    <div class="carousel-item">
      <img src={c} class="d-block YII" alt="3"/>
    </div>
  </div>
  <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="sr-only">Previous</span>
  </a>
  <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="sr-only">Next</span>
  </a>
</div>

   )
    
  }
 
}
 
export default Slider;
import React from 'react';

//imagenes 

import derecha from '../IMG/Botones/Flecha_derecha.png';
import izquierda from '../IMG/Botones/Flecha_izquierda.png';
import '../Component/styles/CarruselServi4.css';
import Aspel1 from '../IMG/LogosB/cfdi-cancelados 1.png';
import Aspel2 from '../IMG/LogosB/cfdi-recibido-reglas 1.png';
import Aspel3 from '../IMG/LogosB/comprobantes-pago-faltantesl 1.png';
import Aspel4 from '../IMG/LogosB/efos 1.png';
import Aspel5 from '../IMG/LogosB/medidor-riesgo-fiscal 1.png';
import Aspel6 from '../IMG/LogosB/repse-registro-proveedores 1.png';
import Aspel7 from '../IMG/LogosB/cumplimiento-obligaciones 1.png';
import Aspel8 from '../IMG/LogosB/triple-conciliacion 1.png';
// import Aspel7 from '../IMG/LogosB/';
import { Reveal, Tween } from 'react-gsap';
import {
    Card, CardImg, CardDeck
} from 'reactstrap';
import { Link } from 'react-router-dom';


class CarruselServi4 extends React.Component {

    render() {

        return (
            <div class="px-0">
                <div id="carouselExampleControls" className="carousel slide carousel-size" data-ride="carousel">
                    {/* <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                        <img src={izquierda} className="col-4 FIzquierda1" alt="" />
                        <span className="sr-only">Previous</span>

                    </a>
                    <a className="carousel-control-next " href="#carouselExampleControls" role="button" data-slide="next">
                        <img src={derecha} className="col-4 FDerecha1" alt="" />
                        <span className="sr-only">Next</span>
                    </a> */}
                    <div className="carousel-inner TamSNT">
                        <div className="carousel-item active">
                            <div className="container">

                                <CardDeck >

                                    <Card style={{ backgroundColor: 'transparent', borderColor: 'transparent' }}>
                                        <center>
                                            <ul>
                                                <li className="Tama23">
                                                    <p className="Tama23">
                                                        ***************************************
                                                    </p>
                                                </li>
                                                <li className="Tama23">
                                                    <p className="Tama2">
                                                        <strong>
                                                            EVITA CANCELACIÓN DE SELLOS
                                                        </strong>
                                                    </p>
                                                </li>
                                                <li className="Tama23">
                                                    <p className="Tama23">
                                                        ***************************************
                                                    </p>
                                                </li>
                                            </ul>
                                        </center>
                                        {/* <p  className="my-1 TipoLetraSNT" align="center">MONITORES</p> */}
                                    </Card>
                                </CardDeck>

                            </div>
                        </div>

                        <div className="carousel-item">
                            <div className="container">

                                <CardDeck >
                                    <Card style={{ backgroundColor: 'transparent', borderColor: 'transparent' }}>
                                        <center>
                                            <ul>
                                                <li className="Tama23">
                                                    <p className="Tama23">
                                                        ***************************************
                                                    </p>
                                                </li>
                                                <li className="Tama23">
                                                    <p className="Tama2">
                                                        <strong>
                                                            PAGO DE MAS IMPUESTOS
                                                        </strong>
                                                    </p>
                                                </li>
                                                <li className="Tama23">
                                                    <p className="Tama23">
                                                        ***************************************
                                                    </p>
                                                </li>
                                            </ul>
                                        </center>
                                        {/* <h2 className="my-1 TipoLetraSNT" align="center">MONITORES</h2> */}
                                    </Card>
                                </CardDeck>

                            </div>
                        </div>

                        <div className="carousel-item">
                            <div className="container">

                                <CardDeck >
                                    <Card style={{ backgroundColor: 'transparent', borderColor: 'transparent' }}>
                                        <center>
                                            <ul>
                                                <li className="Tama23">
                                                    <p className="Tama23">
                                                        ***************************************
                                                    </p>
                                                </li>
                                                <li className="Tama23">
                                                    <p className="Tama2">
                                                        <strong>
                                                            PROBABILIDAD DE UNA AUDITORIA
                                                        </strong>
                                                    </p>
                                                </li>
                                                <li className="Tama23">
                                                    <p className="Tama23">
                                                        ***************************************
                                                    </p>
                                                </li>
                                            </ul>
                                        </center>
                                        {/* <h2 className="my-1 TipoLetraSNT" align="center">LAPTOP's</h2> */}
                                    </Card>
                                </CardDeck>

                            </div>
                        </div>

                        <div className="carousel-item">
                            <div className="container">

                                <CardDeck >
                                    <Card style={{ backgroundColor: 'transparent', borderColor: 'transparent' }}>
                                        <center>
                                            <ul>
                                                <li className="Tama23">
                                                    <p className="Tama23">
                                                        ***************************************
                                                    </p>
                                                </li>
                                                <li className="Tama23">
                                                    <p className="Tama2">
                                                        <strong>
                                                            MULTAS
                                                        </strong>
                                                    </p>
                                                </li>
                                                <li className="Tama23">
                                                    <p className="Tama23">
                                                        ***************************************
                                                    </p>
                                                </li>
                                            </ul>
                                        </center>

                                        {/* <h2 className="my-1 TipoLetraSNT" align="center">CPU's</h2> */}
                                    </Card>
                                </CardDeck>

                            </div>
                        </div>

                    </div>
                </div>
            </div>

        )
    }
}

export default CarruselServi4;
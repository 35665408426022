import React from 'react';

//imagenes 

import derecha from '../IMG/Botones/Flecha_derecha.png';
import izquierda from '../IMG/Botones/Flecha_izquierda.png';
import '../Component/styles/CarruselServi.css';
import Aspel1 from '../IMG/CarruselServicios/MONITOR.png';
import Aspel2 from '../IMG/CarruselServicios/MONITOR2.png';
import Aspel3 from '../IMG/CarruselServicios/Lap.png';
import Aspel4 from '../IMG/CarruselServicios/DELL1.png';
import Aspel5 from '../IMG/CarruselServicios/DELL2.png';
import Aspel6 from '../IMG/CarruselServicios/Servidor.png';
import Aspel7 from '../IMG/CarruselServicios/Switch.png';
import { Reveal, Tween } from 'react-gsap';
import {
  Card, CardImg, CardDeck
} from 'reactstrap';
import { Link } from 'react-router-dom';


class CarruselServicios extends React.Component {

  render() {

    return (
      <div class="px-0">
        <div id="carouselExampleControls" className="carousel slide carousel-size" data-ride="carousel">
          <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
            <img src={izquierda} className="col-4 FIzquierda1" alt="" />
            <span className="sr-only">Previous</span>

          </a>
          <a className="carousel-control-next " href="#carouselExampleControls" role="button" data-slide="next">
            <img src={derecha} className="col-4 FDerecha1" alt="" />
            <span className="sr-only">Next</span>
          </a>
          <div className="carousel-inner TamSNT">
            <div className="carousel-item active">
              <div className="container">

                <CardDeck >

                  <Card style={{ backgroundColor: 'transparent', borderColor: 'transparent' }}>
                    <center>
                      <img src={Aspel1} className="SombraIma" width="32%" ></img>
                    </center>
                    <p className="my-1 TipoLetraSNT" align="center">MONITORES</p>
                  </Card>
                </CardDeck>

              </div>
            </div>

            <div className="carousel-item">
              <div className="container">

                <CardDeck >
                  <Card style={{ backgroundColor: 'transparent', borderColor: 'transparent' }}>
                    <center>
                      <img src={Aspel3} className="SombraIma" width="32%" ></img>
                    </center>
                    <h2 className="my-1 TipoLetraSNT" align="center">LAPTOP's</h2>
                  </Card>
                </CardDeck>

              </div>
            </div>

            <div className="carousel-item">
              <div className="container">

                <CardDeck >
                  <Card style={{ backgroundColor: 'transparent', borderColor: 'transparent' }}>
                    <center>
                      <img src={Aspel4} className="SombraIma" width="32%" ></img>
                    </center>
                    <h2 className="my-1 TipoLetraSNT" align="center">CPU's</h2>
                  </Card>
                </CardDeck>

              </div>
            </div>

            <div className="carousel-item">
              <div className="container">

                <CardDeck >
                  <Card style={{ backgroundColor: 'transparent', borderColor: 'transparent' }}>
                    <center>
                      <img src={Aspel2} className="SombraIma" width="32%" ></img>
                    </center>
                    <h2 className="my-1 TipoLetraSNT" align="center">MONITORES</h2>
                  </Card>
                </CardDeck>

              </div>
            </div>

            <div className="carousel-item">
              <div className="container">

                <CardDeck >
                  <Card style={{ backgroundColor: 'transparent', borderColor: 'transparent' }}>
                    <center>
                      <img src={Aspel6} className="SombraIma" width="32%" ></img>
                    </center>
                    <h2 className="my-1 TipoLetraSNT" align="center">SERVIDORES</h2>
                  </Card>
                </CardDeck>
              </div>
            </div>

            <div className="carousel-item">
              <div className="container">

                <CardDeck >
                  <Card style={{ backgroundColor: 'transparent', borderColor: 'transparent' }}>
                    <center>
                      <img src={Aspel5} className="SombraIma" width="32%" ></img>
                    </center>
                    <h2 className="my-1 TipoLetraSNT" align="center">CPU's</h2>
                  </Card>
                </CardDeck>

              </div>
            </div>

            <div className="carousel-item">
              <div className="container">
                <CardDeck >
                  <Card style={{ backgroundColor: 'transparent', borderColor: 'transparent' }}>
                    <center>
                      <img src={Aspel7} className="SombraIma" width="32%" ></img>
                    </center>
                    <h2 className="my-1 TipoLetraSNT" align="center">SWICH's</h2>
                  </Card>
                </CardDeck>

              </div>
            </div>

          </div>
        </div>
      </div>

    )
  }
}

export default CarruselServicios;
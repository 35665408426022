import React from 'react';
import '../App.css';

class Formulario extends React.Component {
 
  render() {
 
    return (
        <div class="p-4 ">
        <form>
          <h3>REGÍSTRATE PARA ACCEDER A LOS EVENTOS</h3>
          <p>*Campos obligatorios</p>
          <div className="form-group">
            <input type="text" className="form-control" id="Nombre" required placeholder="*ESCRIBA SU NOMBRE"/>            
          </div>
 
          <div className="form-group">
            <input type="email" className="form-control" id="Email" required placeholder="*CORREO ELECTRONICO"/>
          </div>
 
          <div className="form-group">
            <p>*FECHA DE NACIMIENTO
              </p>
            <input type="date" className="form-control" id="Fnacimiento" required />
          </div>
 
          <div className="form-group">
            <input type="text" className="form-control" id="Profesion" required placeholder="*PROFESIÓN"></input>
          </div>
          <div className="form-group">
            <input type="text" className="form-control" id="Hospital" required placeholder="*HOSPITAL DE PROCEDENCIA"></input>
          </div>
          <div className="form-group">
          <input type="checkbox" class="" id="boletin"/>
          <label class="form-check-label" for="exampleCheck1">&nbsp; Deseo recibir boletines informativos sobre el congreso</label>
          </div>
          <div className="form-group">
          <input type="checkbox" class="" id="terminos"/>
          <label class="form-check-label" for="exampleCheck2" required>&nbsp; *He leido y acepto el aviso de privacidad</label>
          </div>
          <button type="submit" className="btn btn-primary col-12">REGISTRARME</button>
          
        </form>
        </div>
    )
    
  }
 
}
 
export default Formulario;
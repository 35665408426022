import React, { useState } from 'react'; 
import ReactGa from 'react-ga';
import boton from '../IMG/Botones/megusta.png';
import boton2 from '../IMG/Botones/compartir.png';
import boton3 from '../IMG/Botones/Like_OFF.png';
import '../Component/styles/BotonMG.css';

class Boton extends React.Component {
 
    /*AQUI SE IMPLEMETA LA FUCNION DEL LOBY DEL CORAZÓN*/
    constructor(props) {
        super(props);
        this.state = { imagen: true };

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        this.setState(prevState => ({
            imagen: !prevState.imagen
        }));
    }

    render() {
    
        
    return(
        <div class="row">

            {/*<input class='flotante' type="image" src={boton} width="30" height="30" ></input>*
            Este es el original
            */}
             {/* Aqui meto el codigo para la imagene del cambio DEL LOBY */}
             {this.state.imagen ?
                            <input class='flotin1' type="image" onClick={this.handleClick}  src={boton3} width="30" height="30" /> : <input type="image" class='flotin1' onClick={this.handleClick}  src={boton} width="30" height="30" />}
             
           
        </div>
        )
    }
     
   } 
  
   export default Boton;
import React from 'react';

//imagenes 

import derecha from '../IMG/Botones/Flecha_derecha.png';
import izquierda from '../IMG/Botones/Flecha_izquierda.png';
import '../Component/styles/CarruselServi3.css';
import Aspel1 from '../IMG/LogosB/cfdi-cancelados 1.png';
import Aspel2 from '../IMG/LogosB/cfdi-recibido-reglas 1.png';
import Aspel3 from '../IMG/LogosB/comprobantes-pago-faltantesl 1.png';
import Aspel4 from '../IMG/LogosB/efos 1.png';
import Aspel5 from '../IMG/LogosB/medidor-riesgo-fiscal 1.png';
import Aspel6 from '../IMG/LogosB/repse-registro-proveedores 1.png';
import Aspel7 from '../IMG/LogosB/cumplimiento-obligaciones 1.png';
import Aspel8 from '../IMG/LogosB/triple-conciliacion 1.png';
import { Reveal, Tween } from 'react-gsap';
import {
  Card, CardImg, CardDeck
} from 'reactstrap';
import { Link } from 'react-router-dom';


class CarruselServi3 extends React.Component {

  render() {

    return (
      <div class="px-0">
        <div id="carouselExampleControls2" className="carousel slide carousel-size" data-ride="carousel">
          <a className="carousel-control-prev" href="#carouselExampleControls2" role="button" data-slide="prev">
            <img src={izquierda} className="col-4 FIzquierda1" alt="" />
            <span className="sr-only">Previous</span>

          </a>
          <a className="carousel-control-next " href="#carouselExampleControls2" role="button" data-slide="next">
            <img src={derecha} className="col-4 FDerecha1" alt="" />
            <span className="sr-only">Next</span>
          </a>
          <div className="carousel-inner TamSNT">
            <div className="carousel-item active">
              <div className="container">

                <CardDeck >

                  <Card style={{ backgroundColor: 'transparent', borderColor: 'transparent' }}>
                    <center>
                      <img src={Aspel1} className="SombraIma" width="32%" ></img>
                    </center>
                    <p  className="my-1 TipoLetraSNT2" align="center">CFDIs Cancelados</p>
                  </Card>
                </CardDeck>

              </div>
            </div>

            <div className="carousel-item">
              <div className="container">

                <CardDeck >
                  <Card style={{ backgroundColor: 'transparent', borderColor: 'transparent' }}>
                    <center>
                      <img src={Aspel2} className="SombraIma" width="32%" ></img>
                    </center>
                    <h2 className="my-1 TipoLetraSNT2" align="center">CFDIs con reglas de conciliación</h2>
                  </Card>
                </CardDeck>

              </div>
            </div>

            <div className="carousel-item">
              <div className="container">

                <CardDeck >
                  <Card style={{ backgroundColor: 'transparent', borderColor: 'transparent' }}>
                    <center>
                      <img src={Aspel3} className="SombraIma" width="32%" ></img>
                    </center>
                    <h2 className="my-1 TipoLetraSNT2" align="center">Comprobantes de pago faltantes</h2>
                  </Card>
                </CardDeck>

              </div>
            </div>

            <div className="carousel-item">
              <div className="container">

                <CardDeck >
                  <Card style={{ backgroundColor: 'transparent', borderColor: 'transparent' }}>
                    <center>
                      <img src={Aspel4} className="SombraIma" width="32%" ></img>
                    </center>
                    <h2 className="my-1 TipoLetraSNT2" align="center">EFOS</h2>
                  </Card>
                </CardDeck>

              </div>
            </div>

            <div className="carousel-item">
              <div className="container">

                <CardDeck >
                  <Card style={{ backgroundColor: 'transparent', borderColor: 'transparent' }}>
                    <center>
                      <img src={Aspel5} className="SombraIma" width="32%" ></img>
                    </center>
                    <h2 className="my-1 TipoLetraSNT2" align="center">Medidor riesgo fiscal</h2>
                  </Card>
                </CardDeck>

              </div>
            </div>

            <div className="carousel-item">
              <div className="container">

                <CardDeck >
                  <Card style={{ backgroundColor: 'transparent', borderColor: 'transparent' }}>
                    <center>
                      <img src={Aspel6} className="SombraIma" width="32%" ></img>
                    </center>
                    <h2 className="my-1 TipoLetraSNT2" align="center">REPSE</h2>
                  </Card>
                </CardDeck>
              </div>
            </div>
            
            <div className="carousel-item">
              <div className="container">
                <CardDeck >
                  <Card style={{ backgroundColor: 'transparent', borderColor: 'transparent' }}>
                    <center>
                      <img src={Aspel7} className="SombraIma" width="32%" ></img>
                    </center>
                    <h2 className="my-1 TipoLetraSNT2" align="center">Cumplimiento de Obligaciones</h2>
                  </Card>
                </CardDeck>

              </div>
            </div>

            <div className="carousel-item">
              <div className="container">
                <CardDeck >
                  <Card style={{ backgroundColor: 'transparent', borderColor: 'transparent' }}>
                    <center>
                      <img src={Aspel8} className="SombraIma" width="32%" ></img>
                    </center>
                    <h2 className="my-1 TipoLetraSNT2" align="center">Triple Conciliación</h2>
                  </Card>
                </CardDeck>

              </div>
            </div>

          </div>
        </div>
      </div>

    )
  }
}

export default CarruselServi3;
import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from 'reactstrap';
import axios from 'axios';
import logo from '../../IMG/Logo/oca-consultoria-logo-ii.png';

function Conexion() {
  // const baseUrl="http://localhost:3000/apiFormulario/";
  const baseUrl = "https://ocaconsultoria.mx/Los_PHP/";
  const [data, setData] = useState([]);
  const [modalInsertar, setModalInsertar] = useState(false);
  const [modalEditar, setModalEditar] = useState(false);
  const [modalEliminar, setModalEliminar] = useState(false);
  const [frameworkSeleccionado, setFrameworkSeleccionado] = useState({
    id_formulario: '',
    nombre: '',
    correo: '',
    num_tel: '',
    comentarios: ''
  });

  const handleChange = e => {
    const { name, value } = e.target;
    setFrameworkSeleccionado((prevState) => ({
      ...prevState,
      [name]: value
    }))
    console.log(frameworkSeleccionado);
  }

  const abrirCerrarModalInsertar = () => {
    setModalInsertar(!modalInsertar);
  }

  const peticionPost = async () => {
    var f = new FormData();
    f.append("nombre", frameworkSeleccionado.nombre);
    f.append("correo", frameworkSeleccionado.correo);
    f.append("num_tel", frameworkSeleccionado.num_tel);
    f.append("comentarios", frameworkSeleccionado.comentarios);
    f.append("METHOD", "POST");
    await axios.post(baseUrl, f)
      .then(response => {
        setData(data.concat(response.data));
        abrirCerrarModalInsertar();
        handleReset();
      }).catch(error => {
        console.log(error);
      })
  }
  const handleReset = () => {
    Array.from(document.querySelectorAll("input, textarea")).forEach(
      input => (input.value = ""),
      textarea => (textarea.value = "")
    );
  };


  return (
    <div className="container" style={{ textAlign: 'center' }}>
      <div>
        <br />


        <Modal isOpen={modalInsertar}>
          <ModalHeader style={{ textAlign: 'left', backgroundColor: '#F2F2F2' }}>
            <div className="container">
              <h4>Sus datos se enviarán a <i>OCA Consultoría en T.I</i></h4>
              <span style={{ fontSize: '10pt' }}>En un maximo de 24 hrs nos pondremos en contacto con usted.</span>
            </div>
          </ModalHeader>

          <ModalFooter align="center">
            <img src={logo} className="mr-auto" width="30%" alt="logo-OCA"></img>
            <button className="btn btn-success" onClick={() => peticionPost()}>Enviar</button>{"   "}
            <button className="btn btn-danger" onClick={() => abrirCerrarModalInsertar()}>Cancelar</button>
          </ModalFooter>
        </Modal>

      </div>


      {/* For */}
      <div className="container card py-3" style={{ textAlign: 'left', backgroundColor: '#F2F2F2' }}>
        <form class="row g-3 needs-validation" >
          <div class="col-md-12 py-2">
            <label for="validationCustomUsername" class="form-label"><h3>Nombre y Apellido: </h3></label>
            <div class="input-group has-validation">
              {/* <span class="input-group-text" id="inputGroupPrepend" style={{ color: '#292563' }} >👤</span> */}
              {/* <Button style={{ backgroundColor: '#7A85CA' }} onClick={() => handleReset()}>Enviar Datos</Button> */}
              <input type="text" name="nombre" class="form-control px-2" id="validationCustomUsername" placeholder="Nombre y Apellido" aria-describedby="inputGroupPrepend" onChange={handleChange} required />
              <div class="invalid-feedback">
                .
              </div>
            </div>
          </div>
          <div class="col-md-12 py-3">
            <label for="validationCustomUsername" class="form-label"><h3>Correo: </h3></label>
            <div class="input-group has-validation">
              {/* <span class="input-group-text" id="inputGroupPrepend" style={{ color: '#292563' }}>@</span> */}
              <input type="text" name="correo" class="form-control px-2" id="validationCustomUsername" placeholder="informes@ocaconsultoria.mx " aria-describedby="inputGroupPrepend" onChange={handleChange} required />
              <div class="invalid-feedback">
                .
              </div>
            </div>
          </div>
          <div class="col-md-12 py-3">
            <label for="validationCustomUsername" class="form-label"><h3>Número de teléfono: </h3></label>
            <div class="input-group has-validation">
              {/* <span class="input-group-text" id="inputGroupPrepend" style={{ color: '#292563' }}>📞</span> */}
              <input type="text" name="num_tel" class="form-control px-2" id="validationCustomUsername" placeholder=" 55-2158-5639 " aria-describedby="inputGroupPrepend" onChange={handleChange} required />
              <div class="invalid-feedback">
                .
              </div>
            </div>
          </div>
          <div class="col-md-12 py-3">
            <label for="validationCustomUsername" class="form-label"><h3>Comentarios: </h3></label>
            <div class="input-group has-validation">
              <textarea type="text" name="comentarios" class="form-control px-2" id="validationCustomUsername" placeholder=" Escriba sus dudas o comentarios: " aria-describedby="inputGroupPrepend" onChange={handleChange} ></textarea>
              <div class="invalid-feedback">
                .
              </div>
            </div>
          </div>


          {/* <div class="col-12">
            <button class="btn btn-primary" type="submit" onClick={() => abrirCerrarModalInsertar()}>Submit form</button>
          </div> */}
          <div class="row">
            <div class="col-sm-12 m-0">
              <Button style={{ backgroundColor: '#7A85CA' }} onClick={() => abrirCerrarModalInsertar()}>Enviar Datos</Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Conexion;
import React from 'react';

//imagenes 
import derecha from '../IMG/Botones/Flecha_derecha.png';
import izquierda from '../IMG/Botones/Flecha_izquierda.png';
import '../Component/styles/CarruselServi2.css';
import Rele1 from '../IMG/Servicios/Datos curiosos/DC1.jpg';
import Rele2 from '../IMG/Servicios/Datos curiosos/DC2.jpg';
import Rele3 from '../IMG/Servicios/Datos curiosos/DC3.jpg';
import Rele4 from '../IMG/Servicios/Datos curiosos/DC4.jpg';
// import Rele5 from '../IMG/Servicios/Datos curiosos/DC5.jpg';
import {
    Card, CardImg, CardDeck
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { Reveal, Tween } from 'react-gsap';

class CarruselRele1 extends React.Component {

    render() {

        return (
            <div class="py-0">
                <div id="carouselExampleControls" className="carousel slide carousel-size" data-ride="carousel">
                    <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                        <img src={izquierda} className="col-4 FIzquierda1" alt="" />
                        <span className="sr-only">Previous</span>

                    </a>
                    <a className="carousel-control-next " href="#carouselExampleControls" role="button" data-slide="next">
                        <img src={derecha} className="col-4 FDerecha1" alt="" />
                        <span className="sr-only">Next</span>
                    </a>
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <div className="container">
                                <center>
                                    <img src={Rele1} className="" width="32%" ></img>
                                </center>
                            </div>
                        </div>

                        <div className="carousel-item">
                            <div className="container">
                                <center>
                                    <img src={Rele2} className="" width="32%" ></img>
                                </center>
                            </div>
                        </div>

                        <div className="carousel-item">
                            <div className="container">
                                <center>
                                    <img src={Rele3} className="" width="32%" ></img>
                                </center>
                            </div>
                        </div>

                        <div className="carousel-item">
                            <div className="container">
                                <center>
                                    <img src={Rele4} className="" width="32%" ></img>
                                </center>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        )
    }
}

export default CarruselRele1;
import { Redirect } from 'react-router-dom';
import React from 'react';
import Footer from './Footer';
import Talleres from './Talleres';
import ReactGa from 'react-ga';



class Programa extends React.Component {
  
    render() {
      
       return (
          
          <> 
    <div>
 </div> 
 <div>
</div> 
<div class="py-5 container">
</div> 

<div class="py-5 container">
</div> 

<div class="py-5 container">
<Talleres/>
</div> 

    <Footer />
       </>
    
    )
  
}
}
   export default Programa;
import React, { Component } from 'react';
// import { render } from 'react-dom';
import '../../Component/styles/CarruselTecnm.css';
import $ from 'jquery';
import logo from '../../IMG/Logo/oca-consultoria-logo-ii.png';

class CarruselTEcnm4 extends Component {
    constructor() {
        super();
    }

    componentDidMount() {
        // My attempts to always have carousel begin at index 0 on show event
        // $('.largeModal').on('show.bs.modal', function() {
        //   console.log('show.bs.modal event');
        // 	$('.carousel').carousel(0);
        // });
        // $('.largeModal').on('show.bs.modal', function() {
        //   console.log('show.bs.modal event');
        // 	$('#myCarousel').carousel(0);
        // });
    }

    render() {
        const imgArr = [
            {
                className: 'img-size',
                src:
                    'https://ocaconsultoria.mx/static/media/Deducibles.275287e0.jpg',
                alt: 'Third slide'
            },
            {
                className: 'img-size',
                src:
                    'https://ocaconsultoria.mx/Aspel/COI.jpg',
                alt: 'Third slide'
            },
            {
                className: 'img-size',
                src:
                    'https://ocaconsultoria.mx/Aspel/1_COI.jpg',
                alt: 'Third slide'
            },
            {
                className: 'img-size',
                src:
                    'https://ocaconsultoria.mx/Aspel/2_COI_2.jpg',
                alt: 'Third slide'
            },
            {
                className: 'img-size',
                src:
                    'https://ocaconsultoria.mx/Aspel/3_COI.jpg',
                alt: 'Third slide'
            }
        ];

        return (
            <div className='container'>
                <div class='row m-0'>
                    <div class='col text-center'>
                        <button
                            type='button'
                            className='btn btn-outline-danger btn-sm CarruDatos'
                            data-toggle='modal'
                            data-target='#largeModal3'
                            style={{borderRadius:'20px'}}
                        >
                            Más información
                        </button>
                    </div>
                </div>

                {/* Modal*/}
                <div
                    className='modal fade'
                    id='largeModal3'
                    tabIndex='-1'
                    role='dialog'
                    aria-labelledby='basicModal'
                    aria-hidden='true'
                >
                    <div className='modal-dialog modal-lg'>
                        <div className='modal-content'>
                            <div className='modal-body'>
                                {/* Carousel */}
                                <div
                                    id='myCarousel3'
                                    className='carousel slide'
                                    data-ride='carousel'
                                >
                                    <ol className='carousel-indicators'>
                                        <li
                                            data-target='#myCarousel3'
                                            data-slide-to='0'
                                            className='active'
                                        ></li>
                                        <li data-target='#myCarousel3' data-slide-to='1'></li>
                                        <li data-target='#myCarousel3' data-slide-to='2'></li>
                                        <li data-target='#myCarousel3' data-slide-to='3'></li>
                                        <li data-target='#myCarousel3' data-slide-to='4'></li>
                                    </ol>
                                    <div className='carousel-inner'>
                                        {imgArr.map((item, index) => (
                                            <div
                                                key={index}
                                                className={
                                                    index === 0 ? 'carousel-item active' : 'carousel-item'
                                                }
                                            >
                                                <img
                                                    className={item.className}
                                                    src={item.src}
                                                    alt={item.alt}
                                                    style={{width:'100%'}}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                    <a
                                        className='carousel-control-prev'
                                        href='#myCarousel3'
                                        role='button'
                                        data-slide='prev'
                                    >
                                        <span
                                            className='carousel-control-prev-icon'
                                            aria-hidden='true'
                                        >
                                            {' '}
                                        </span>
                                        <span className='sr-only'>Previous</span>
                                    </a>
                                    <a
                                        className='carousel-control-next'
                                        href='#myCarousel3'
                                        role='button'
                                        data-slide='next'
                                    >
                                        <span
                                            className='carousel-control-next-icon'
                                            aria-hidden='true'
                                        ></span>
                                        <span className='sr-only'>Next</span>
                                    </a>
                                </div>
                            </div>
                            <div className='modal-footer'>
                                <img src={logo} width="20%" className="mr-auto" ></img>
                                <button
                                    type='button'
                                    className='btn btn-danger'
                                    data-dismiss='modal'
                                >
                                    Cerrar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default CarruselTEcnm4; 
import React, { useRef }  from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import ReactDOM from 'react-dom'; // Librería react-dom 
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'; // Librería react-router-dom
import './index.css';
// import * as serviceWorker from './serviceWorker';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/js/bootstrap.min.js';
import emailjs from '@emailjs/browser';
import './App';

// Páginas del Sitio Web
import Home from './paginas/Home'; 
import Inicio from './paginas/Inicio';
import Eventos from './paginas/Eventos';
import Programa from './paginas/Programa';
import ServiciosOCA from './paginas/DetallesEventos/Evento1';
import ServiciosOCA2 from './paginas/DetallesEventos/Evento2';
import CClientes from './paginas/CarruselClientes';
import ServiciosOCA3 from './paginas/DetallesEventos/Evento3';
import SerOCAPag from './paginas/DetallesEventos/Evento5';
import Nosotros from './paginas/DetallesEventos/Evento4';
import SistemasAspel from './paginas/DetallesAComercial/AComercial1';
import PQelegirnos from './paginas/PQelegirnos';
import AvisoP from './paginas/AvisoP';
import Contacto from './paginas/elementos/Nuevacon';
import Ejem from './paginas/FormEj2';
import Formulario from './paginas/Formulario/Conexion';
import FormularioPrueba from './paginas/Formulario/Formu';
import CanacoADM from './paginas/Canaco';
import APP from './App';
// Configuración de la rutas del Sitio Web
ReactDOM.render(
  
    <Router>
     <div>
      <Switch>
 
         {/* Páginas */}

         <Route exact path='/' component={Home}/>
         <Route exact path='/Inicio' component={Inicio} />
         <Route path='/Eventos' component={Eventos} />
         <Route path='/AvisoP' component={AvisoP} />
         <Route path='/PQelegirnos' component={PQelegirnos} />
         <Route path='/Ejem' component={Ejem} />
         <Route path='/Programa' component={Programa}  />
         <Route path='/Formulario' component={Formulario}  />
         <Route path='/FormularioPrueba' component={FormularioPrueba}  />
         <Route path='/ServiciosOCA' component={ServiciosOCA} />
         <Route path='/ServiciosOCA2' component={ServiciosOCA2} />
         <Route path='/ServiciosOCA3' component={ServiciosOCA3} />
         <Route path='/SerOCAPag' component={SerOCAPag} />
         <Route path='/Nosotros' component={Nosotros} />
         <Route path='/Contacto' component={Contacto} />
         <Route path='/SistemasAspel' component={SistemasAspel} />
         <Route path='/CClientes' component={CClientes} />
         <Route path='/CanacoADM' component={CanacoADM} />
       	</Switch>
     </div>
    </Router>,
  document.getElementById('root')
);

 



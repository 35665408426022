import React from 'react';

//imagenes 

import derecha from '../IMG/Botones/Flecha_derecha.png';
import izquierda from '../IMG/Botones/Flecha_izquierda.png';
import '../Component/styles/Stand1.css';
import Aspel1 from '../IMG/LogosAspel/ASPEL-ICONO VERT_SAE.png';
import Aspel2 from '../IMG/LogosAspel/ASPEL-ICONO VERT_COI.png';
import Aspel3 from '../IMG/LogosAspel/ASPEL-ICONO VERT_NOI.png';
import Aspel4 from '../IMG/LogosAspel/ASPEL-ICONO VERT_BANCO.png';
import Aspel5 from '../IMG/LogosAspel/ASPEL-ICONO VERT_PROD.png';
import Aspel6 from '../IMG/LogosAspel/ASPEL-ICONO VERT_CAJA.png';
import Aspel7 from '../IMG/LogosAspel/ASPEL-ICONO VERT_FACTURE.png';
import { Reveal, Tween } from 'react-gsap';
import {
  Card, CardImg, CardDeck
} from 'reactstrap';
import { Link } from 'react-router-dom';


class CarruselEv extends React.Component {

  render() {

    return (
      <div class="py-0">
        <div id="carouselExampleControls" className="carousel slide carousel-size" data-ride="carousel">
          <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
            <img src={izquierda} className="col-4 FIzquierda1" alt="" />
            <span className="sr-only">Previous</span>

          </a>
          <a className="carousel-control-next " href="#carouselExampleControls" role="button" data-slide="next">
            <img src={derecha} className="col-4 FDerecha1" alt="" />
            <span className="sr-only">Next</span>
          </a>
          <div className="carousel-inner">
            <div className="carousel-item active">
              <div className="container">
                <a href="#SAE">
                  <CardDeck >

                    <Card style={{ backgroundColor: 'transparent', borderColor: 'transparent' }}>
                      <center>
                        <img src={Aspel1} className="" width="25%" ></img>
                      </center>
                      <h2 ClassName="my-1" align="center">Administración</h2>
                    </Card>
                  </CardDeck>
                </a>
              </div>
            </div>

            <div className="carousel-item">
              <div className="container">
                <a href="#COI" >
                  <CardDeck >
                    <Card style={{ backgroundColor: 'transparent', borderColor: 'transparent' }}>
                      <center>
                        <img src={Aspel2} className="" width="25%" ></img>
                      </center>
                      <h2 ClassName="my-1" align="center">Contabilidad</h2>
                    </Card>
                  </CardDeck>
                </a>
              </div>
            </div>

            <div className="carousel-item">
              <div className="container">
                <a href="#NOI" >
                  <CardDeck >
                    <Card style={{ backgroundColor: 'transparent', borderColor: 'transparent' }}>
                      <center>
                        <img src={Aspel3} className="" width="25%" ></img>
                      </center>
                      <h2 ClassName="my-1" align="center">Nómina</h2>
                    </Card>
                  </CardDeck>
                </a>
              </div>
            </div>

            <div className="carousel-item">
              <div className="container">
                <a href="#BANCO" >
                  <CardDeck >
                    <Card style={{ backgroundColor: 'transparent', borderColor: 'transparent' }}>
                      <center>
                        <img src={Aspel4} className="" width="25%" ></img>
                      </center>
                      <h2 ClassName="my-1" align="center">Tesorería</h2>
                    </Card>
                  </CardDeck>
                </a>
              </div>
            </div>

            <div className="carousel-item">
              <div className="container">
                <a href="#PROD" >
                  <CardDeck >
                    <Card style={{ backgroundColor: 'transparent', borderColor: 'transparent' }}>
                      <center>
                        <img src={Aspel5} className="" width="25%" ></img>
                      </center>
                      <h2 ClassName="my-1" align="center">Manufactura</h2>
                    </Card>
                  </CardDeck>
                </a>
              </div>
            </div>

            <div className="carousel-item">
              <div className="container">
                <a href="#CAJA" >
                  <CardDeck >
                    <Card style={{ backgroundColor: 'transparent', borderColor: 'transparent' }}>
                      <center>
                        <img src={Aspel6} className="" width="25%" ></img>
                      </center>
                      <h2 ClassName="my-1" align="center">Punto de Venta</h2>
                    </Card>
                  </CardDeck>
                </a>
              </div>
            </div>

            <div className="carousel-item">
              <div className="container">
                <a href="#FACTURE" >
                  <CardDeck >
                    <Card style={{ backgroundColor: 'transparent', borderColor: 'transparent' }}>
                      <center>
                        <img src={Aspel7} className="" width="25%" ></img>
                      </center>
                      <h2 ClassName="my-1" align="center">Facturación</h2>
                    </Card>
                  </CardDeck>
                </a>
              </div>
            </div>
            
          </div>
        </div>
      </div>

    )
  }
}

export default CarruselEv;
import React, { Component } from 'react';
// import { render } from 'react-dom';
import '../../Component/styles/CarruselCANACO.css';
import $ from 'jquery';
import logo from '../../IMG/Logo/oca-consultoria-logo-ii.png';
import ServOCA from '../DetallesEventos/Evento1';
import { FormGroup, Label, Input, Button, Jumbotron, Container, Modal, ModalBody, ModalFooter, ModalHeader, Card, CardBody, CardHeader, CardFooter } from 'reactstrap';
import emailjs from '@emailjs/browser';
import '../elementos/FormulaEMAIL2.css';

class CarruselCANACO extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name18: '',
            apellido18: '',
            abrirModalSNT18: false,
            ACmodal18: false
        }
        this.form = React.createRef();
        this.toggle = this.toggle.bind(this)
    }

    toggle() {
        this.setState({
            ACmodal18: !this.state.ACmodal18
        });
    }
    abrirModalSNT18 = () => {
        this.setState({
            abrirModalSNT18: true,
        });
    }
    handleChange = (e) => {
        const { value } = e.target
        this.setState({ name: value })
    }
    handleChange2 = (e) => {
        const { value } = e.target
        this.setState({ apellido: value })
    }
    handleReset = () => {
        Array.from(document.querySelectorAll("input, textarea")).forEach(
            input => (input.value = ""),
            textarea => (textarea.value = "")
        );
        this.setState({
            itemvalues: [{}]
        });
    };

    sendEmail = (e) => {
        e.preventDefault();
        emailjs.sendForm(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TEMPLATE_ID, this.form.current, process.env.REACT_APP_PUBLIC_KEY).then((result) => {
            this.toggle();
            console.log('Hola: ' + result.text);
            this.handleReset();
        },
            (error) => {
                alert('Surprise...');
                console.log(error.text);
            });
    };

    componentDidMount() {

    }

    render() {
        const imgArr = [
            {
                className: 'img-size1',
                src:
                    'https://ocaconsultoria.mx/Aspel/IMA1.jpeg',
                alt: 'First slide',
                url: '/ServiciosOCA'
            },
            {
                className: 'img-size1',
                src:
                    'https://ocaconsultoria.mx/Aspel/IMA2.jpeg',
                alt: 'Second slide',
                url: '/ServiciosOCA'
            },
            {
                className: 'img-size1',
                src:
                    'https://ocaconsultoria.mx/Aspel/IMA3.jpeg',
                alt: 'Third slide',
                url: '/SistemasAspel'
            },
            {
                className: 'img-size1',
                src:
                    'https://ocaconsultoria.mx/Aspel/IMA4.jpeg',
                alt: 'Third slide',
                url: '/ServiciosOCA'
            },
            {
                className: 'img-size1',
                src:
                    'https://ocaconsultoria.mx/Aspel/IMA5.jpeg',
                alt: 'Third slide',
                url: '/ServiciosOCA'
            },
            {
                className: 'img-size1',
                src:
                    'https://ocaconsultoria.mx/Aspel/IMA6.jpeg',
                alt: 'Third slide',
                url: '/ServiciosOCA'
            }
            ,
            {
                className: 'img-size1',
                src:
                    'https://ocaconsultoria.mx/Aspel/IMA7.jpeg',
                alt: 'Third slide',
                url: '/ServiciosOCA'
            }
            ,
            {
                className: 'img-size1',
                src:
                    'https://ocaconsultoria.mx/Aspel/IMA8.jpeg',
                alt: 'Third slide',
                url: '/ServiciosOCA'
            }
            ,
            {
                className: 'img-size1',
                src:
                    'https://ocaconsultoria.mx/Aspel/IMA9.jpeg',
                alt: 'Third slide',
                url: '/ServiciosOCA'
            }
        ];

        const linkArr = [
            {
                className: 'img-size2',
                url: '../Footer.js'
            }
        ];
        return (
            <>
                <div className="FormularioCarrusel821">
                    <div
                        id='myCarousel777'
                        className='carousel slide'
                        data-ride='carousel'
                    >
                        <ol className='carousel-indicators'>
                            <li
                                data-target='#myCarousel777'
                                data-slide-to='0'
                                className='active'
                            ></li>
                            <li data-target='#myCarousel777' data-slide-to='1'></li>
                            <li data-target='#myCarousel777' data-slide-to='2'></li>
                            <li data-target='#myCarousel777' data-slide-to='3'></li>
                            <li data-target='#myCarousel777' data-slide-to='4'></li>
                            <li data-target='#myCarousel777' data-slide-to='5'></li>
                            <li data-target='#myCarousel777' data-slide-to='6'></li>
                            <li data-target='#myCarousel777' data-slide-to='7'></li>
                            <li data-target='#myCarousel777' data-slide-to='8'></li>
                            <li data-target='#myCarousel777' data-slide-to='9'></li>
                        </ol>
                        <div className='carousel-inner'>
                            {imgArr.map((item, index) => (
                                <div
                                    key={index}
                                    className={
                                        index === 0 ? 'carousel-item active' : 'carousel-item'
                                    }
                                >
                                    <a href={item.url}>
                                        <div className="CarusellImaHei">
                                            <img
                                                className={item.className}
                                                src={item.src}
                                                alt={item.alt}
                                                style={{borderRadius:'10px 10px 10px 10px', padding:'5px'}}
                                            />
                                        </div>
                                        <br />
                                    </a>
                                </div>
                            ))}
                        </div>
                        <a
                            className='carousel-control-prev'
                            href='#myCarousel777'
                            role='button'
                            data-slide='prev'
                        >
                            <span
                                className='carousel-control-prev-icon1'
                                aria-hidden='true'
                            >
                                {' '}
                            </span>
                            <span className='sr-only'>Previous</span>
                        </a>
                        <a
                            className='carousel-control-next'
                            href='#myCarousel777'
                            role='button'
                            data-slide='next'
                        >
                            <span
                                className='carousel-control-next-icon1'
                                aria-hidden='true'
                            ></span>
                            <span className='sr-only'>Next</span>
                        </a>
                    </div>
                </div>
            </>
        );
    }
}
export default CarruselCANACO; 
import React from 'react';
// import { Redirect } from 'react-router-dom';
import CarruselEv from './CarruselEv'
import Footer from './Footer';
import '../Component/styles/Home.css';


class Events extends React.Component {

  render() {
    



      return (

        <>


          <br />

          <main role="main" className="flex-shrink-0 mt-5">
            <CarruselEv />{/*aqui se manda a llamar el carrusel*/}


            <div className="container ">{/*se coloca un contenedor para el espacio de los laterales*/}
              <br />
              <br />
            </div>

         
          </main>
          <br />
          <br />

          <Footer />

        </>

      )

    }
  }


export default Events;
import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Table } from "reactstrap";
import { Reveal, Tween } from 'react-gsap';
import SiigoAspelSAE from '../../../IMG/Logos_Aspel/Iconos_aspel/ASPEL-ICONO VERT_SAE.png'

function TablaSAE() {
    return (
        <div style={{
            display: 'block', width: 500, padding: 30
        }}>
            <h4 className="pb-4" style={{ color: 'grey' }}>Sistema Administrativo Empresarial</h4>
            <Table bordered={false}>
                <thead>
                    <tr>
                        <th>Nivel</th>
                        <th>Versión</th>
                        <th>Duración</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Básico</td>
                        <td>9.0</td>
                        <td>Hrs.</td>
                    </tr>
                    <tr>
                        <td>Intermedio</td>
                        <td>9.0</td>
                        <td>Hrs.</td>
                    </tr>
                    <tr>
                        <td>Avanzado</td>
                        <td>9.0</td>
                        <td>Hrs.</td>
                    </tr>
                </tbody>
            </Table>
            <Reveal repeat>
                <Tween
                    from={{ y: '25%', opacity: -20, rotation: 1 }}
                    to={{ y: '0', opacity: 10, rotation: 0 }}
                    duration={1.4}
                >
                    <img src={SiigoAspelSAE} className="pt-1" width="50%" />
                </Tween>
            </Reveal>
        </div>
    );
}

export default TablaSAE;
import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
// imports 
function App() {
    const form = useRef();
    const sendEmail = (e) => {
        e.preventDefault();
        emailjs.sendForm(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TEMPLATE_ID, form.current, process.env.REACT_APP_PUBLIC_KEY).then((result) => {
            alert('message sent successfully...');
            console.log(result.text);
        },
            (error) => {
                alert('me...');
                console.log(error.text);
            });
    }; 
    return (
    <div> <h1>Contact Form</h1> <form className='cf' ref={form} onSubmit={sendEmail}> // div container with input element </form> </div> 
    ); }
     export default App;
import React from 'react';
import transmitir2 from '../IMG/transmit/L2.png';
import { Link } from 'react-router-dom';
import ReactGa from 'react-ga';
import Relevantes from '../Component/Relevantes.css'



class Acomercial extends React.Component {

  // componentDidMount = async () => {
  //   ReactGa.initialize('UA-190917969-1');
  //   ReactGa.pageview('AComercial');
  // }

  render() {

    return (
      <div className="SombraSNT" style={{ backgroundColor: '#eff3fb' }}>
        <strong>
          <div className="ScrollRelevantes">
            <div className="p-1 ml-1">
              <div className="row">
                <center>
                  {/* <div className="" style={{ backgroundColor: '#DCDCDC', filter: 'drop-shadow(1px 1px 7px #0c0b0b)', borderRadius: '8px 8px' }}> */}
                  <h2 className="PruebaTa">RELEVANTES</h2>
                  {/* </div> */}
                </center>
              </div>

              <p className="parraRELE pt-2"><strong>CFDI 4.0</strong></p>
              <h7>Cumple con los requisitos de Facturación Electronica</h7>
              <br />
              <h7><strong style={{ color: 'red' }}>01 Abril 2023, Fecha Limite</strong></h7>
              {/* <p><b>Transmitiendo ahora  </b><img src={transmitir2} width="28" height="18"/> </p> */}
            </div>
            <hr color="#292563" />

            <div className="Col lg-12">
              <p className="parraRELE"><strong>Siigo Nube</strong></p>
              <h7>Sistema administrativo 100% en la nube.</h7>
              <br />
              <h7><strong>01 de Mayo 2023</strong></h7>

              {/* <p><b>Transmitiendo ahora  </b><img src={transmitir2} width="28" height="18"/> </p> */}
            </div>

            <hr color="#292563" />
            <div className="">
              <p className="parraRELE pt-1"><strong>Vacaciones Dignas</strong></p>
              <h7>Conoce los nuevos cambios</h7>
              <br />
              <h7><strong>20 de Enero 2023</strong></h7>
              {/* <p><b>Transmitiendo ahora  </b><img src={transmitir2} width="28" height="18"/> </p> */}
            </div>

            <hr color="#292563" />
            <div className="Col lg-12">
              <p className="parraRELE"><strong>Servidor Virtual</strong></p>
              <h7>Se trata de un espacio en la nube donde las empresas pueden...</h7>
              <br />

              {/* <p><b>Transmitiendo ahora  </b><img src={transmitir2} width="28" height="18"/> </p> */}
            </div>

            <hr color="#292563" />
            <div className="Col lg-12">
              <p className="parraRELE"><strong>Carta Porte 3.0</strong></p>
              <h7>Conoce los nuevos cambios en el complemento carta porte...</h7>
              <br />
              <h7><strong>25 de Octubre 2023</strong></h7>

              {/* <p><b>Transmitiendo ahora  </b><img src={transmitir2} width="28" height="18"/> </p> */}
            </div>
          </div>
          <br />
          <a href="Contacto" style={{ textDecoration: 'none' }} className="btn btn-primary col-12 my-0" >
            Solicitar información
          </a>
          {/* <Link to="#Relevantes" style={{ textDecoration: 'none' }} className="btn btn-primary col-12 my-0" > Conocer más...</Link> */}
        </strong>
      </div>
    )

  }

}
export default Acomercial;
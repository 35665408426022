import React from 'react';
import { Link } from 'react-router-dom';

import derecha from '../IMG/Botones/Flecha_derecha.png';
import izquierda from '../IMG/Botones/Flecha_izquierda.png';
import '../Component/styles/CarruselPagina.css';
import I1 from '../IMG/OCA/Imagen2.jpg';
import I2 from '../IMG/OCA/Imagen3.jpg';
import I3 from '../IMG/OCA/Imagen4.jpg';
import I4 from '../IMG/OCA/Imagen5.jpg';
import I5 from '../IMG/OCA/Cliente.jpg';
import {
    Card, CardImg, CardDeck
} from 'reactstrap';
import '../App.css';
import '../Component/styles/Stand1.css';


class CarruselPagina extends React.Component {

    render() {
        

        return (

            <div class="py-4">

                {/* <h2 className="alinearTitulo container text-center"> CLIENTES SATISFECHOS </h2> */}
                <div id="carouselExampleControls" className="carousel slide carousel-size" data-ride="carousel">
                    <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                        <img src={izquierda} className="col-4 FIzquierda4" alt="" />
                        <span className="sr-only">Previous</span>

                    </a>
                    <a className="carousel-control-next " href="#carouselExampleControls" role="button" data-slide="next">
                        <img src={derecha} className="col-4 FDerecha5" alt="" />
                        <span className="sr-only">Next</span>
                    </a>
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <div className="CCCC">
                                <div className="container">
                                    <Link  to="/">
                                        <CardDeck >

                                            <Card style={{ backgroundColor: 'transparent', borderColor: 'transparent' }}>
                                                <center>
                                                    <img src={I1} className="SNT888" width="50%" ></img>
                                                    <img src={I4} className="SNT888" width="50%" ></img>
                                                    <img src={I3} className="SNT888" width="50%" ></img>
                                                </center>
                                            </Card>
                                        </CardDeck>
                                    </Link>
                                </div>
                            </div>
                        </div>

                        <div className="carousel-item">
                            <div className="">
                                <div className="container">
                                    <Link  to="/Areacomercial" >
                                        <CardDeck >


                                            <Card style={{ backgroundColor: 'transparent', borderColor: 'transparent' }}>
                                                <center>
                                                    <img src={I2} className="SNT888" width="50%" ></img>
                                                    <img src={I1} className="SNT888" width="50%" ></img>
                                                    <img src={I4} className="SNT888" width="50%" ></img>
                                                </center>
                                            </Card>

                                            {/* <Card>
                                                <CardImg top src={I3} width="100%" className="p-0 container" alt="Card image cap" />
                                                <CardImg top src={I2} width="100%" className="p-0 container" alt="Card image cap" />
                                            </Card> */}

                                        </CardDeck>
                                    </Link>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        )

    }

}

export default CarruselPagina;

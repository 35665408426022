import React from 'react';
//imagenes 
import a from '../IMG/Marca/Mrc_1.png';
import $ from 'jquery';
import b from '../IMG/Marca/Mrc_1.png';
import c from '../IMG/Marca/Mrc_1.png';
import Mapa from '../IMG/Mapa/Map_1.png';
import Suc from '../IMG/LogosAspel/Suc.jpg';
import { Link } from 'react-router-dom';
import '../Component/styles/Stand1.css';
import derecha from '../IMG/Botones/Flecha_derecha.png';
import izquierda from '../IMG/Botones/Flecha_izquierda.png';
import L1 from '../IMG/LogosEmpresas/MuseoNA.jpeg';
import L2 from '../IMG/LogosEmpresas/PATRONATO.jpeg';
import L3 from '../IMG/LogosEmpresas/G_SELCA.png';
import L4 from '../IMG/LogosEmpresas/curtisl.png';
import L5 from '../IMG/LogosEmpresas/AIDI_I.jpeg';
import DatosC2 from '../paginas/AreasInteres/DatosCur2';
import Rel1 from '../IMG/Servicios/Datos curiosos/DatosCuriosos.jpg';
import Rel2 from '../IMG/Servicios/Tips.jpg';
import Rel3 from '../IMG/Servicios/Corte.jpg';
import Rel4 from '../IMG/Servicios/Deducibles.jpg';
// import CarruselTEcnm from '../paginas/DetallesAComercial/CarruselTEcnm';
import CarruselTEcnm from './DetallesAComercial/CarruselTEcnm';
import CarruselTEcnm2 from '../paginas/DetallesAComercial/CarruselTEcnm2';
import CarruselTEcnm3 from '../paginas/DetallesAComercial/CarruselTEcnm3';
import CarruselTEcnm4 from '../paginas/DetallesAComercial/CarruselTEcnm4';
import CarruselTEcnm5 from './DetallesAComercial/CarruselTEcnm5';
import CarruselTEcnm6 from './DetallesAComercial/CarruselTEcnm6';
import { Reveal, Tween } from 'react-gsap';
//Aqui mandamos a llmar nuestros estilos para hacer el cambio de flechas - slider

class AreasInteres extends React.Component {
    // Carrusel CLientes
    render() {
        return (
            <>
                <br />
                {/* Acomodo CARDs */}
                <center>
                    <div className="contain2">
                        <div className="row">
                            <div className="col-12 col-sm-12 col-lg-3 col-xl-3 col-xxl-3">
                                <div className="row">
                                    <div className="col-12 col-sm-6 col-lg-12 col-xl-12 col-xxl-12">
                                        <div className="py-2 px-4">
                                            <div class="card d-flex justify-content-between align-items-center SombraSNT" style={{ backgroundColor: '#eff3fb' }} >
                                                {/* ES AQUI */}
                                                {/* <input type="image" src={Rel1} width="246" height="120" className="py-2 px-2 "></input> */}
                                                <img src={Rel1} width="100%" height="120" className="py-2 px-2 "></img>
                                                <div className="card-body py-2 ">
                                                    <div className="TamaS mb-0 pb-0">
                                                        <h4 >Datos Curiosos...</h4>
                                                    </div>
                                                    <hr style={{ backgroundColor: '#020135' }} />
                                                    <div className="TamaSN" style={{ backgroundColor: '' }}>
                                                        <p class="card-text py-0">Presiona el botón y descubre contenido importante de <strong> &quot;Tecnología&quot;</strong> </p>
                                                    </div>
                                                </div>
                                                <div className="card-footer p-1 ">
                                                    {/* <CarruselTEcnm /> */}
                                                    .................................
                                                </div>
                                            </div>

                                            <div className="Mov">
                                                <CarruselTEcnm5 />
                                                <br />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6 col-lg-12 col-xl-12 col-xxl-12">
                                        <div className="px-4">
                                            <div className="card d-flex justify-content-between align-items-center SombraSNT" style={{ backgroundColor: '#eff3fb' }}>
                                                <input type="image" src={Rel2} onClick={this.abrirModalREL2} width="100%" height="120" className="py-2 px-2 "></input>
                                                <div className="card-body py-2">
                                                    <h4>Controla Gastos...</h4>
                                                    <hr className="" style={{ backgroundColor: '#020135' }} />
                                                    <div className="TamaSN" style={{ backgroundColor: '' }}>
                                                        <p className="card-text py-0">En esta sección encontrarás <strong>&quot;Tips&quot;</strong> para controlar los gastos de tu negocio </p>
                                                    </div>
                                                </div>
                                                <div className="card-footer p-1 ">
                                                    {/* <CarruselTEcnm2 /> */}.................................
                                                </div>
                                            </div>

                                            <div className="Mov222">
                                                <CarruselTEcnm2 />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* CARD 2 */}
                            </div>
                            {/* Imagen Sucursales */}
                            <div className="col-12 col-sm-6 col-lg-6 col-xl-6 col-xxl-6">

                                <h2 align="center" className="py-4">SUCURSALES</h2>
                                <img src={Suc} className="ImaSuc SombraSNT" alt="" />
                                <Link to="/Contacto" style={{ textDecoration: 'none', backgroundColor: '#04036B' }} className="btn btn-primary container-fluid mb-4 SonmbraSNT">UBICACIONES EN EL MAPA</Link>
                            </div>
                            {/* Termina Seccion Imagen Sucursales */}
                            <div className=" col-12 col-sm-6 col-lg-3 col-xl-3 col-xxl-3">
                                {/* <div className="row"> */}
                                {/* <div className="col-12 col-sm-6 col-lg-12 col-xl-12 col-xxl-12"> */}
                                {/* - */}
                                <div class="py-2 px-4">
                                    <div class="">
                                        <div class="card d-flex justify-content-between align-items-center SombraSNT" style={{ backgroundColor: '#eff3fb' }} >
                                            {/* ES AQUI */}
                                            {/* <input type="image" src={Rel1} width="246" height="120" className="py-2 px-2 "></input> */}
                                            <input type="image" src={Rel3} onClick={this.abrirModalREL3} width="100%" height="120" className="py-2 px-2 "></input>
                                            <div className="card-body py-2">
                                                <div className="TamaS mb-0 pb-0">
                                                    <h4>Primer Nómina del Año...</h4>
                                                </div>
                                                <hr className="" style={{ backgroundColor: '#020135' }} />
                                                <div className="TamaSN" style={{ backgroundColor: '' }}>
                                                    <p className="card-text py-0">Conoce toda la información sobre como comenzar con tu primer nómina del año</p>
                                                </div>
                                            </div>
                                            <div className="card-footer p-1 ">
                                                {/* <CarruselTEcnm /> */}.................................
                                            </div>
                                        </div>

                                        <div className="Mov4">
                                            <CarruselTEcnm3 />
                                            <br />
                                        </div>
                                    </div>
                                </div>
                                {/* </div> */}
                                {/* - */}
                                {/* CARD4 */}
                                {/* <div className="col-12 col-sm-6 col-lg-12 col-xl-12 col-xxl-12"> */}
                                <div class="py-2 MovCar px-4">
                                    <div className="">
                                        <div className="card d-flex justify-content-between align-items-center SombraSNT" style={{ backgroundColor: '#eff3fb' }}>
                                            <input type="image" src={Rel4} onClick={this.abrirModalREL4} width="100%" height="120" className="py-2 px-2 "></input>
                                            {/* < Boton /> */}
                                            {/* <input class='flotin2' type="image" src={boton2}  width="30" height="30"></input> */}
                                            <div className="card-body py-2">
                                                <h4>Declaración Anual...</h4>
                                                <hr className="" style={{ backgroundColor: '#020135' }} />
                                                <div className="TamaSN" style={{ backgroundColor: '' }}>
                                                    <p className="card-text py-0">Conoce los gastos que pueden ser deducibles en tu declaración Anual</p>
                                                </div>
                                            </div>
                                            <div className="card-footer p-1 ">
                                                {/* <CarruselTEcnm4 /> */}.................................
                                            </div>
                                        </div>
                                        <div className="Mov3 ">
                                            <CarruselTEcnm4 />
                                            <br />
                                        </div>
                                    </div>
                                </div>
                                {/* </div> */}
                                {/* </div> */}
                            </div>
                        </div>
                    </div>
                </center>

                {/* Acomodo CARDs */}

                {/* <div className="contain" >
                    <center>
                        <div className="row">
                            <div className="col-sm-3">
                                <div class="py-2">
                                    <div class="ACD1 justify-content-between">
                                        <div class="card  d-flex justify-content-between align-items-center SombraSNT" style={{ backgroundColor: '#eff3fb' }} >
                                            <img src={Rel1} width="246" height="120" className="py-2 px-2 "></img>
                                            <div className="card-body py-2 "><h4>Datos Curiosos...</h4>
                                                <p class="card-text py-0">Presiona el botón y descubre contenido importante de <strong> &quot;Tecnología&quot;</strong> </p>
                                            </div>
                                            <div className="card-footer p-1 ">
                                                .................................
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="Mov">
                                    <CarruselTEcnm5 />
                                    <br />
                                </div>


                                <div className="ACD1">
                                    <div className="card d-flex justify-content-between align-items-center SombraSNT" style={{ backgroundColor: '#eff3fb' }}>
                                        <input type="image" src={Rel2} onClick={this.abrirModalREL2} width="246" height="120" className="py-2 px-2 "></input>
                                        <div className="card-body py-2"><h4>Controla Gastos...</h4>
                                            <p className="card-text py-0">En esta sección encontrarás <strong>&quot;Tips&quot;</strong> para controlar los gastos de tu negocio </p>
                                        </div>
                                        <div className="card-footer p-1 ">
                                            .................................
                                        </div>
                                    </div>
                                </div>

                                <div className="Mov2">
                                    <CarruselTEcnm2 />
                                </div>
                            </div>
                            
                            <div className="col-sm-6">

                                <h2 align="center" className="py-1">SUCURSALES</h2>
                                <img src={Suc} className="ImaSuc SombraSNT" alt="" />
                                <Link to="/Contacto" style={{ textDecoration: 'none', backgroundColor: '#04036B' }} className="btn btn-primary container-fluid mb-4 SonmbraSNT">UBICACIONES EN EL MAPA</Link>
                            </div>
                            
                            <div className="col-sm-3">
                                <div class="py-2">
                                    <div class="ACD1 justify-content-between">
                                        <div class="card  d-flex justify-content-between align-items-center SombraSNT" style={{ backgroundColor: '#eff3fb' }} >
                                            
                                            <input type="image" src={Rel3} onClick={this.abrirModalREL3} width="246" height="120" className="py-2 px-2 "></input>
                                            <div className="card-body py-2"><h4>Primer Nómina del Año...</h4>
                                                <p className="card-text py-0">Conoce toda la información sobre como comenzar con tu primer nómina del año</p>
                                            </div>
                                            <div className="card-footer p-1 ">
                                                .................................
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="Mov4">
                                    <CarruselTEcnm3 />
                                    <br />
                                </div>
                                
                                <div class="py-2 MovCar">
                                    <div className="ACD1">
                                        <div className="card d-flex justify-content-between align-items-center SombraSNT" style={{ backgroundColor: '#eff3fb' }}>
                                            <input type="image" src={Rel4} onClick={this.abrirModalREL4} width="246" height="120" className="py-2 px-2 "></input>
                                            
                                            <div className="card-body py-2"><h4>Declaracion Anual...</h4>
                                                <p className="card-text py-0">Conoce los gastos que pueden ser deducibles en tu declaración Anual</p>
                                            </div>
                                            <div className="card-footer p-1 ">
                                                .................................
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="Mov3 ">
                                    <CarruselTEcnm4 />
                                    <br />
                                </div>
                            </div>
                        </div>
                    </center>
                </div> */}
                <br />

            </>
        )
    }
}

export default AreasInteres; 
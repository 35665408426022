import React, { Component } from 'react';
// import { render } from 'react-dom';
import '../../Component/styles/CarruselTecnm.css';
import $ from 'jquery';
import logo from '../../IMG/Logo/oca-consultoria-logo-ii.png';

class CarruselTEcnm5 extends Component {
    constructor() {
        super();
    }

    componentDidMount() {
        // My attempts to always have carousel begin at index 0 on show event
        // $('.largeModal').on('show.bs.modal', function() {
        //   console.log('show.bs.modal event');
        // 	$('.carousel').carousel(0);
        // });
        // $('.largeModal').on('show.bs.modal', function() {
        //   console.log('show.bs.modal event');
        // 	$('#myCarousel').carousel(0);
        // });
    }

    render() {
        const imgArr = [
            {
                className: 'img-size',
                src:
                    'https://ocaconsultoria.mx/static/media/DatosCuriosos.efdf87af.jpg',
                alt: 'First slide',
                width:'1%'
            },
            {
                className: 'img-size',
                src:
                    'https://ocaconsultoria.mx/static/media/DC1.52c577da.jpg',
                alt: 'Second slide'
            },
            {
                className: 'img-size',
                src:
                    'https://ocaconsultoria.mx/static/media/DC2.eabb5294.jpg',
                alt: 'Third slide'
            },
            {
                className: 'img-size',
                src:
                    'https://ocaconsultoria.mx/static/media/DC3.5bd2a2ea.jpg',
                alt: 'Third slide'
            },
            {
                className: 'img-size',
                src:
                    'https://ocaconsultoria.mx/static/media/DC4.de81965d.jpg',
                alt: 'Third slide'
            },
            {
                className: 'img-size',
                src:
                    'https://ocaconsultoria.mx/static/media/DC5.e8657f65.jpg',
                alt: 'Third slide'
            }
        ];

        return (
            <div className='container'>
                <div class='row m-0'>
                    <div class='col text-center'>
                        <button
                            type='button'
                            className='btn btn-outline-danger btn-sm CarruDatos'
                            data-toggle='modal'
                            data-target='#largeModal5'
                            style={{borderRadius:'20px'}}
                        >
                            Más información
                        </button>
                    </div>
                </div>

                {/* Modal*/}
                <div className="">
                    <div
                        className='modal fade'
                        id='largeModal5'
                        tabIndex='-1'
                        role='dialog'
                        aria-labelledby='basicModal'
                        aria-hidden='true'
                    >
                        <div className='modal-dialog modal-lg'>
                            <div className='modal-content'>
                                <div className='modal-body'>
                                    {/* Carousel */}
                                    <div
                                        id='myCarousel5'
                                        className='carousel slide'
                                        data-ride='carousel'
                                    >
                                        <ol className='carousel-indicators'>
                                            <li
                                                data-target='#myCarousel5'
                                                data-slide-to='0'
                                                className='active'
                                            ></li>
                                            <li data-target='#myCarousel5' data-slide-to='1'></li>
                                            <li data-target='#myCarousel5' data-slide-to='2'></li>
                                            <li data-target='#myCarousel5' data-slide-to='3'></li>
                                            <li data-target='#myCarousel5' data-slide-to='4'></li>
                                        </ol>
                                        <div className='carousel-inner'>
                                            {imgArr.map((item, index) => (
                                                <div
                                                    key={index}
                                                    className={
                                                        index === 0 ? 'carousel-item active' : 'carousel-item'
                                                    }
                                                >
                                                    <img
                                                        className={item.className}
                                                        src={item.src}
                                                        alt={item.alt}
                                                        style={{width:'100%'}}
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                        <a
                                            className='carousel-control-prev'
                                            href='#myCarousel5'
                                            role='button'
                                            data-slide='prev'
                                        >
                                            <span
                                                className='carousel-control-prev-icon'
                                                aria-hidden='true'
                                            >
                                                {' '}
                                            </span>
                                            <span className='sr-only'>Previous</span>
                                        </a>
                                        <a
                                            className='carousel-control-next'
                                            href='#myCarousel5'
                                            role='button'
                                            data-slide='next'
                                        >
                                            <span
                                                className='carousel-control-next-icon'
                                                aria-hidden='true'
                                            ></span>
                                            <span className='sr-only'>Next</span>
                                        </a>
                                    </div>
                                </div>
                                <div className='modal-footer'>
                                    <img src={logo} width="20%" className="mr-auto" ></img>
                                    <button
                                        type='button'
                                        className='btn btn-danger'
                                        data-dismiss='modal'
                                    >
                                        Cerrar
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default CarruselTEcnm5; 
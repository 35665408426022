import React from 'react';
import { Link } from 'react-router-dom';
import Footer from '../Footer';
import '../../Component/styles/DetEventos.css';
import A from '../../IMG/Eventos/EVNT_M1.png';
import {
    Modal, ModalHeader, ModalFooter, ModalBody, Button, Nav, NavItem, NavLink, TabContent, TabPane
} from 'reactstrap';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitterSquare, faFacebook } from "@fortawesome/free-brands-svg-icons";
import ReactGa from 'react-ga';
import Carrusel from '../CarruselServi3';
import Carrusel2 from '../CarruselServi4';
import { Reveal, Tween } from 'react-gsap';
import SiigoAspel from '../../IMG/Servicios/Sistemas/siigo-aspel.png';
import Cotiza from '../../IMG/Servicios/Sistemas/Llama3.png'
import TablaAspelSAE from './Aspel/TablaSAE';
import TablaAspelNOI from './Aspel/TablaNOI';
import TablaAspelCOI from './Aspel/TablaCOI';
import TablaAspelBANCO from './Aspel/TablaBANCO';
import TablaAspelPROD from './Aspel/TablaPROD';
import TablasAspelCAJA from './Aspel/TablaCAJA';
import TablasAspelADM from './Aspel/TablaADM';
import Burger from '../../IMG/Servicios/Sistemas/iconBURGER-Ph.png';
import FormularioCurso from './Aspel/FormularioCurso'

class CursosAspel extends React.Component {
    // Modal registrarse y compartir

    state = {
        abierto: false
    };




    abrirModal = () => {
        this.setState({ abierto: !this.state.abierto });
    }

    // ----------------------------

    componentDidMount = async () => {
        ReactGa.initialize('UA-258737016-1');
        ReactGa.pageview('Servicios OcaConsultoria 3');
    }

    constructor(props) {
        super(props);
        this.state = { imagen: true };
        this.handleClick = this.handleClick.bind(this);
        this.toggle = this.toggle.bind(this);
        this.state = {
            activeTab: '1'
        };
    }

    handleClick() {
        this.setState(prevState => ({
            imagen: !prevState.imagen
        }));
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });

        }
    }



    render() {

        return (
            <>
                <main role="main">
                    <div className="align-items-center">
                        <div class="row">
                            <div class="col-3 col-sm-3 col-lg-3 col-xl-3 col-xxl-3">
                                <div className="card-body">
                                    <div className="v-line"></div>
                                    <p className="card-title TpLetraSNT fondoSistemaAs"> &nbsp;
                                        <img src={SiigoAspel} className="pt-1" width="50%" height="54px" />
                                    </p>
                                    <h4 class=" card-text text-left py-4">Cursos disponibles: </h4>
                                    <ul className="">
                                        <Nav tabs className=" nav navbar  navbar-expand-lg CursoSAspel">
                                            <button className="pl-0 navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo04" aria-controls="navbarTogglerDemo04" aria-expanded="false" aria-label="Toggle navigation" >
                                                {/* <span class="navbar-toggler-icon" style={{ backgroundColor: 'black' }}></span> */}
                                                <img src={Burger} className="navbar-toggler-icon" width="80%" height="60px" />
                                            </button>

                                            <div className="collapse navbar-collapse Nav flex-column CursoSAspel" id="navbarTogglerDemo04" align="left">
                                                <li className="py-2 mr-auto" style={{ color: '#00006A' }}>
                                                    <NavItem>
                                                        <NavLink
                                                            className={classnames({ active: this.state.activeTab === '1' })}
                                                            onClick={() => { this.toggle('1'); }}
                                                        >
                                                            <strong style={{ display: 'flex' }} className="InterLineCurso">
                                                                <TabContent activeTab={this.state.activeTab}>
                                                                    <TabPane tabId="1">
                                                                        {/* <p>»↣↝↠↮⇏◈≫⋗⋩⋯⨮⪫⪢</p> */}
                                                                        <span style={{ color: '#1B3F94' }}>↝</span>
                                                                    </TabPane>
                                                                </TabContent>
                                                                Sistema Administrativo Empresarial.</strong>
                                                        </NavLink>
                                                    </NavItem>
                                                </li>
                                                <li className="py-2 mr-auto"  style={{ color: '#00006A' }}>
                                                    <NavItem>
                                                        <NavLink
                                                            className={classnames({ active: this.state.activeTab === '2' })}
                                                            onClick={() => { this.toggle('2'); }}
                                                        >
                                                            <strong style={{ display: 'flex' }} className="InterLineCurso">
                                                                <TabContent activeTab={this.state.activeTab}>
                                                                    <TabPane tabId="2">
                                                                        {/* <p>»↣↝↠↮⇏◈≫⋗⋩⋯⨮⪫⪢</p> */}
                                                                        <span style={{ color: '#1B3F94' }}>↝</span>
                                                                    </TabPane>
                                                                </TabContent>
                                                                Sistema de Nómina Integral.</strong>
                                                        </NavLink>
                                                    </NavItem>
                                                </li>
                                                <li className="py-2 mr-auto" style={{ color: '#00006A' }}>
                                                    <NavItem >
                                                        <NavLink
                                                            className={classnames({ active: this.state.activeTab === '3' })}
                                                            onClick={() => { this.toggle('3'); }}
                                                        >
                                                            <strong style={{ display: 'flex' }} className="InterLineCurso">
                                                                <TabContent activeTab={this.state.activeTab}>
                                                                    <TabPane tabId="3">
                                                                        {/* <p>»↣↝↠↮⇏◈≫⋗⋩⋯⨮⪫⪢</p> */}
                                                                        <span style={{ color: '#1B3F94' }}>↝</span>
                                                                    </TabPane>
                                                                </TabContent>
                                                                Sistema de Contabilidad Integral.</strong>
                                                        </NavLink>
                                                    </NavItem>
                                                </li>
                                                <li className="py-2 mr-auto" style={{ color: '#00006A' }}>
                                                    <NavItem >
                                                        <NavLink
                                                            className={classnames({ active: this.state.activeTab === '4' })}
                                                            onClick={() => { this.toggle('4'); }}
                                                        >
                                                            <strong style={{ display: 'flex' }} className="InterLineCurso">
                                                                <TabContent activeTab={this.state.activeTab}>
                                                                    <TabPane tabId="4">
                                                                        {/* <p>»↣↝↠↮⇏◈≫⋗⋩⋯⨮⪫⪢</p> */}
                                                                        <span style={{ color: '#1B3F94' }}>↝</span>
                                                                    </TabPane>
                                                                </TabContent>
                                                                Sistema de Control Bancario.</strong>
                                                        </NavLink>
                                                    </NavItem>
                                                </li>
                                                <li className="py-2 mr-auto" style={{ color: '#00006A' }}>
                                                    <NavItem >
                                                        <NavLink
                                                            className={classnames({ active: this.state.activeTab === '5' })}
                                                            onClick={() => { this.toggle('5'); }}
                                                        >
                                                            <strong style={{ display: 'flex' }} className="InterLineCurso">
                                                                <TabContent activeTab={this.state.activeTab}>
                                                                    <TabPane tabId="5">
                                                                        {/* <p>»↣↝↠↮⇏◈≫⋗⋩⋯⨮⪫⪢</p> */}
                                                                        <span style={{ color: '#1B3F94' }}>↝</span>
                                                                    </TabPane>
                                                                </TabContent>
                                                                Sistema de Control de Producción.</strong>
                                                        </NavLink>
                                                    </NavItem>
                                                </li>
                                                <li className="py-2 mr-auto" style={{ color: '#00006A' }}>
                                                    <NavItem >
                                                        <NavLink
                                                            className={classnames({ active: this.state.activeTab === '6' })}
                                                            onClick={() => { this.toggle('6'); }}
                                                        >
                                                            <strong style={{ display: 'flex' }} className="InterLineCurso">
                                                                <TabContent activeTab={this.state.activeTab}>
                                                                    <TabPane tabId="6">
                                                                        {/* <p>»↣↝↠↮⇏◈≫⋗⋩⋯⨮⪫⪢</p> */}
                                                                        <span style={{ color: '#1B3F94' }}>↝</span>
                                                                    </TabPane>
                                                                </TabContent>
                                                                Sistema de Punto de Venta y Administración de Comercios.</strong>
                                                        </NavLink>
                                                    </NavItem>
                                                </li>
                                                <li className="py-2 mr-auto" style={{ color: '#00006A' }}>
                                                    <NavItem >
                                                        <NavLink
                                                            className={classnames({ active: this.state.activeTab === '7' })}
                                                            onClick={() => { this.toggle('7'); }}
                                                        >
                                                            <strong style={{ display: 'flex' }} className="InterLineCurso">
                                                                <TabContent activeTab={this.state.activeTab}>
                                                                    <TabPane tabId="7">
                                                                        {/* <p>»↣↝↠↮⇏◈≫⋗⋩⋯⨮⪫⪢</p> */}
                                                                        <span style={{ color: '#1B3F94' }}>↝</span>
                                                                    </TabPane>
                                                                </TabContent>
                                                                ADM: Herramienta de administración desde la nube.</strong>
                                                        </NavLink>
                                                    </NavItem>
                                                </li>
                                            </div>
                                        </Nav>
                                    </ul>
                                    <hr color="#7E80A9" />
                                </div>
                            </div>
                            <div class="col-9 col-sm-9 col-lg-9 col-xl-9 col-xxl-9 align-items-center">
                                <TabContent activeTab={this.state.activeTab}>
                                    <TabPane tabId="1">
                                        <h2 className="pt-3" style={{ fontSize: '3rem', color: '#EC0928' }}>Cursos disponibles para SAE</h2>
                                        <TablaAspelSAE />
                                    </TabPane>
                                    <TabPane tabId="2">
                                        <h2 className="pt-3" style={{ fontSize: '3rem', color: '#FD8204' }}>Cursos disponibles para NOI</h2>
                                        <TablaAspelNOI />
                                    </TabPane>
                                    <TabPane tabId="3">
                                        <h2 className="pt-3" style={{ fontSize: '3rem', color: '#00ADD9' }}>Cursos disponibles para COI</h2>
                                        <TablaAspelCOI />
                                    </TabPane>
                                    <TabPane tabId="4">
                                        <h2 className="pt-3 AspelBANCOIMA" style={{ fontSize: '3rem', color: '' }}>Cursos disponibles para BANCO</h2>
                                        <TablaAspelBANCO />
                                    </TabPane>
                                    <TabPane tabId="5">
                                        <h2 className="pt-3" style={{ fontSize: '3rem', color: '#4D4084' }}>Cursos disponibles para PROD</h2>
                                        <TablaAspelPROD />
                                    </TabPane>
                                    <TabPane tabId="6">
                                        <h2 className="pt-3" style={{ fontSize: '3rem', color: '#F3C300' }}>Cursos disponibles para CAJA</h2>
                                        <TablasAspelCAJA />
                                    </TabPane>
                                    <TabPane tabId="7">
                                        <h2 className="pt-3" style={{ fontSize: '3rem', color: '#00577D' }}>Cursos disponibles para ADM</h2>
                                        <TablasAspelADM />
                                        {/* <Reveal repeat>
                                            <Tween
                                                from={{ y: '25%', opacity: -20, rotation: 1 }}
                                                to={{ y: '0', opacity: 10, rotation: 0 }}
                                                duration={1.4}
                                            >
                                                <img src={A} className="" width="24%" height="300px"></img>
                                            </Tween>
                                            <Tween
                                                from={{ y: '-25%', opacity: -20, rotation: 1 }}
                                                to={{ y: '0', opacity: 10, rotation: 0 }}
                                                duration={1.4}
                                            >
                                                <img src={A} className="" width="70%" height="300px"></img>
                                            </Tween>
                                        </Reveal> */}
                                    </TabPane>
                                </TabContent>

                            </div>
                            {/* <div class="container"> */}
                            {/* <div class="d-flex flex-row-reverse bd-highlight"> */}
                            {/* <div class="p-2 bd-highlight mt-2"><Link to={'/ServiciosOCA4/'} type="button" class="btn btn-info"> SIGUIENTE &gt;</Link></div> */}
                            {/* <div class="p-2 bd-highlight align-items-left mr-auto"> */}
                            {/* <div class="p-2 bd-highlight"><Link to={'/ServiciosOCA2/'} type="button" class="btn" style={{backgroundColor:'#7E80A9'}}> &lt; REGRESAR</Link></div> */}
                            {/* <input class='' type="image" src={boton2} width="30" height="30"></input> */}
                            {/* </div> */}
                            {/* </div> */}
                            {/* </div> */}
                        </div>
                        {/* <div class="container">
                            <p className="card-title TpLetraSNT">¿Comó trabaja?</p>
                            <p className="card-text text-justify pb-4 parrafo">
                                Ofrece una solución de analítica de datos que trabaja a tu favor y detecta automáticamente discrepancias, que representan un riesgo fiscal para tu empresa.
                            </p>
                        </div> */}
                        <div className="row">
                            <div className="col-sm-6">
                                <p className="py-4"></p>
                                <p className="py-4"></p>
                                <img src={Cotiza} className="CotizaCurso pb-1" width="100%" height="350px" />
                            </div>
                            <div className="col-sm-6">
                                <FormularioCurso />
                            </div>

                        </div>
                    </div>
                </main>
                {/* <Footer /> */}

            </>

        )
    }

}


export default CursosAspel;
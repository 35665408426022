import React from 'react';
import {
    Card, Button, CardImg, CardTitle, CardText, CardDeck,
    CardSubtitle, CardBody, CardFooter, CardHeader
} from 'reactstrap';
import TeamVi2 from '../IMG/TeamViewer/Team.jpg';
import '../Component/styles/TeamViewer.css';
import FonVidTeam from '../Video/FondoCanaco/Team.mp4';

class TeamViewer extends React.Component {

    render() {

        return (
            <>
                <video src={FonVidTeam} loop muted autoplay="true" preload="auto" className="FondoVTeam"></video>
                <div className="container-fluid5 ">
                    <div className="row">
                        <div className="card col-6 text-left justify-content-center" style={{ backgroundColor: 'transparent', borderColor: 'transparent' }}>
                            <h2 align="center" className="HomeTeam2"> Soporte Remoto <br />OCA CONSULTORIA EN T.I. </h2>
                        </div>
                        <div className="card col-6 justify-content-center text-center" style={{ backgroundColor: 'transparent', borderColor: 'transparent' }}>
                            <center>
                                <img src={TeamVi2} className=" imaTeam821 text-center justify-content-center pb-2" align="center" width="80%" ></img>
                                <a className="btn btn-primary col-9" href="https://download.teamviewer.com/download/TeamViewer_Setup.exe" target="_blank" style={{ borderRadius: '50px' }}>
                                    Descargar
                                </a>
                            </center>
                        </div>
                    </div>
                </div>
            </>
        )
    }

}

export default TeamViewer;
import React from 'react';
// import { Redirect } from 'react-router-dom';
// import { Link } from 'react-router-dom';
// import ReactGa from 'react-ga';
// import Logero from '../JInicio';
import Menu from '../Menu';
// import Boton from '../Boton';
import '../../Component/styles/DAComercial.css';
import '../../Component/styles/Home.css';
// import boton from '../../IMG/Botones/megusta.png';
// import boton2 from '../../IMG/Botones/compartir.png';
// import Rel1 from '../../IMG/Servicios/Datos curiosos/DatosCuriosos.jpg';
import Rel2 from '../../IMG/Servicios/Tips.jpg';
import Rel3 from '../../IMG/Servicios/Corte.jpg';
import Rel4 from '../../IMG/Servicios/Deducibles.jpg';
// import Rele1 from '../../IMG/Servicios/Datos curiosos/DC1.jpg';
// import Rele2 from '../../IMG/Servicios/Datos curiosos/DC2.jpg';
// import Rele3 from '../../IMG/Servicios/Datos curiosos/DC3.jpg';
// import Rele4 from '../../IMG/Servicios/Datos curiosos/DC4.jpg';
// import Rele5 from '../../IMG/Servicios/Datos curiosos/DC5.jpg';
// import izquierda from '../../IMG/Botones/Flecha_izquierda.png';
// import derecha from '../../IMG/Botones/Flecha_derecha.png';
// import '../../Component/styles/Stand1.css';

// import { Reveal, Tween } from 'react-gsap';
// import { useFullScreen } from 'unc-react-creator';
import {
    Modal, ModalHeader, ModalBody
} from 'reactstrap';
import Carru1 from '../CarruselRele1';
import CarruselTEcnm from '../../paginas/DetallesAComercial/CarruselTEcnm';
import CarruselTEcnm2 from '../../paginas/DetallesAComercial/CarruselTEcnm2';
import CarruselTEcnm3 from '../../paginas/DetallesAComercial/CarruselTEcnm3';
import CarruselTEcnm4 from '../../paginas/DetallesAComercial/CarruselTEcnm4';

class DatosCur extends React.Component {

    state = {
        Rel1: false,
        Rel2: false,
        Rel3: false,
        Rel4: false,
    }

    abrirModalREL1 = () => {
        this.setState({ Rel1: !this.state.Rel1 });
    }
    abrirModalREL2 = () => {
        this.setState({ Rel2: !this.state.Rel2 });
    }
    abrirModalREL3 = () => {
        this.setState({ Rel3: !this.state.Rel3 });
    }
    abrirModalREL4 = () => {
        this.setState({ Rel4: !this.state.Rel4 });
    }




    render() {

        // const [callFullScreen] = useFullScreen();

        return (
            <>

                <Menu />
                {/* ---------------------------------------------------------- */}
                {/* Seccion Modales Áreas de Interés */}
                {/* ---------------------------------------------------------- */}

                {/* CARD_1 */}
                <Modal size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered isOpen={this.state.Rel1} sixe="lg">
                    <ModalHeader className="border-0 bg-dark" cssModule={{ 'modal-title': 'w-100 text-right' }}>
                        <h6 className=" modal-title text-center col-12" id="exampleModalLabel">OCA CONSULTORIA EN T.I. &nbsp;

                            <button type="button" class="close btn-danger" aria-label="Close" onClick={this.abrirModalREL1}>
                                <span aria-hidden="true">&times;</span>
                            </button></h6>
                    </ModalHeader>
                    <ModalBody>
                        {/* <Carrusel_Ima /> */}
                    </ModalBody>
                </Modal>


                {/* CARD_2 */}
                <Modal size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered isOpen={this.state.Rel2} sixe="lg">
                    <ModalHeader className="border-0 bg-dark" cssModule={{ 'modal-title': 'w-100 text-right' }}>
                        <h6 className=" modal-title text-center col-12" id="exampleModalLabel">OCA CONSULTORIA EN T.I. &nbsp;

                            <button type="button" class="close btn-danger" aria-label="Close" onClick={this.abrirModalREL2}>
                                <span aria-hidden="true">&times;</span>
                            </button></h6>
                    </ModalHeader>
                    <ModalBody>
                        <img src={Rel2} className="" width="100%" ></img>
                        {/* <Carru1 /> */}
                    </ModalBody>
                </Modal>
                <Modal size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered isOpen={this.state.Rel3} sixe="lg">
                    <ModalHeader className="border-0 bg-dark" cssModule={{ 'modal-title': 'w-100 text-right' }}>
                        <h6 className=" modal-title text-center col-12" id="exampleModalLabel">OCA CONSULTORIA EN T.I. &nbsp;

                            <button type="button" class="close btn-danger" aria-label="Close" onClick={this.abrirModalREL3}>
                                <span aria-hidden="true">&times;</span>
                            </button></h6>
                    </ModalHeader>
                    <ModalBody>
                        <img src={Rel3} className="" width="100%" ></img>
                        {/* <Carru1 /> */}
                    </ModalBody>
                </Modal>
                <Modal size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered isOpen={this.state.Rel4} sixe="lg">
                    <ModalHeader className="border-0 bg-dark" cssModule={{ 'modal-title': 'w-100 text-right' }}>
                        <h6 className=" modal-title text-center col-12" id="exampleModalLabel">OCA CONSULTORIA EN T.I. &nbsp;

                            <button type="button" class="close btn-danger" aria-label="Close" onClick={this.abrirModalREL4}>
                                <span aria-hidden="true">&times;</span>
                            </button></h6>
                    </ModalHeader>
                    <ModalBody>
                        <img src={Rel4} className="" width="100%" ></img>
                        {/* <Carru1 /> */}
                    </ModalBody>
                </Modal>
                <main role="main" className="flex-shrink-0 mt-5">                



                </main>
                {/* <Carrusel_Ima /> */}

                <div className="container">
                    <main role="main" className=" flex-shrink-0 mt-12">
                        {/* <Carru1 /> */}
                        {/*  */}
                        <div className="row">
                            

                            <div class="py-2">
                                <div className="ACD1">
                                    <div className="card d-flex justify-content-between align-items-center SombraSNT" style={{ backgroundColor: '#eff3fb' }}>
                                        <input type="image" src={Rel3} onClick={this.abrirModalREL3} width="246" height="120" className="py-2 px-2 "></input>
                                        {/* < Boton/>
      <input class='flotin2' type="image" src={boton2}  width="30" height="30"></input> */}
                                        <div className="card-body py-2"><h4>Primer Nómina del Año...</h4>
                                            <p className="card-text py-0">Conoce toda la información sobre como comenzar con tu primer nómina del año</p>
                                        </div>
                                        <div className="card-footer p-1 ">
                                            <CarruselTEcnm3 />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="py-2">
                                <div className="ACD1">
                                    <div className="card d-flex justify-content-between align-items-center SombraSNT" style={{ backgroundColor: '#eff3fb' }}>
                                        <input type="image" src={Rel4} onClick={this.abrirModalREL4} width="246" height="120" className="py-2 px-2 "></input>
                                        {/* < Boton /> */}
                                        {/* <input class='flotin2' type="image" src={boton2}  width="30" height="30"></input> */}
                                        <div className="card-body py-2"><h4>Declaracion Anual...</h4>
                                            <p className="card-text py-0">Conoce los gastos que pueden ser deducibles en tu declaración Anual</p>
                                        </div>
                                        <div className="card-footer p-1 ">
                                            <CarruselTEcnm4 />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>

                </div>
            </>

        )

    }

}

export default DatosCur;


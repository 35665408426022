import React from 'react';
import Boton from './Boton'
import A from '../IMG/modal/Ev1.png';
import B from '../IMG/modal/Ev2.png';
import C from '../IMG/modal/Ev3.png';
import D from '../IMG/modal/Ev4.png';
import E from '../IMG/modal/Ev5.png';
import logo from '../IMG/Logo/oca-consultoria-logo-ii.png';
import '../Component/styles/Servicios.css'
import { Reveal, Tween } from 'react-gsap';
import {
       Card, Button, CardImg, CardTitle, CardText, CardDeck,
       CardSubtitle, CardBody
} from 'reactstrap';

class PQelegirnos extends React.Component {

       render() {

              return (
                     <>
                     {/* <img src={logo} width="100%" ></img> */}
                            {/* <h2>EVENTOS RELEVANTES</h2> */}
                            <Reveal repeat>
                                    <Tween
                                        from={{ y: '-45px', opacity: 0, rotation: 0.5 }}
                                        to={{ y: '0px', opacity: 1, rotation: 0 }}
                                        duration={1.1}
                                    >
                                           <h2 ClassName="shadow " align="center"> ¿Porque Elegirnos?</h2>
                                {/* <p style={{ color: '#878787' }}><b>Account Manager</b></p> */}
                                </Tween>
                                </Reveal>
                            
                            <br />
                     </>

              )

       }

}

export default PQelegirnos;
import React from 'react';

import boton from '../IMG/Botones/megusta.png';
import boton2 from '../IMG/Botones/compartir.png';
import A from '../IMG/Jumbotron/1.png'
import Menu from './JInicio';
import Menu2 from './Menu';
import logo from '../IMG/Logo/oca-consultoria-logo-ii.png';
import "../Component/styles/Jumbotron.css";
import {
  Card, Button, CardImg, CardTitle, CardText, CardDeck,
  CardSubtitle, CardBody, CardFooter
} from 'reactstrap';


class AvisoP extends React.Component {


  render() {

    return (
      <>


        <Menu />
        <Menu2 />
        <center>
          {/* <div class="col col-10">
            <br />
            <br />
            <div className="card-body">
              <img src={logo} width="40%" className="imafiA1" ></img>
            </div>
          </div> */}

          <CardDeck className="SombraSNT container col-sm-9">
            <Card>
              {/* <CardImg top src={A} width="100%" className="py-2 container" alt="Card image cap" /> */}
              {/* < Boton /> */}
              <CardBody className="pt-1">

                <h2 class=" text-center ">Aviso de Privacidad</h2>
                <p className="text-justify">
                  Este informe se ha realizado tomando en consideración los informes, papeles, y demás registros que el cliente nos ha proporcionado para realizar nuestra labor. Dicha información es única y exclusivamente responsabilidad de la administración del cliente, y nuestra responsabilidad como profesionales especialistas en la materia, se circunscribe a emitir una opinión del tratamiento fiscal o contable respecto de los documentos, papeles e informes por el cliente ofrecidos.
                </p>
                <p className="text-justify">
                  La opinión contenida en el presente informe constituye únicamente una interpretación unilateral de los temas aquí tratados, por lo tanto, de ninguna forma se ubica en las hipótesis previstas en las fracciones I, II y III del artículo 89 del Código Fiscal de la Federación, y la presente no pretende contravenir las disposiciones e interpretaciones que pueden ser diversas a los criterios dados a conocer por las autoridades fiscales.
                </p>
                <p className="text-justify">
                  El presente correo electrónico contempla la ética profesional del que lo suscribe y en apego a las diversas disposiciones fiscalas aplicables en México, por lo que el emisor del presente correo no se encuentra en los supuestos marcados en el artículo 95 del Código Fiscal de la Federación.
                </p>
                <hr color="#292563" />
                <h2 className="text-center">
                  Responsable y Domicilio:
                </h2>
                <p className="text-justify">
                  Los datos anteriores son recabados por la Firma “OCA Consultoría en T.I.”, con domicilio en Av. Francisco Morazán No. 81, 2do piso, Depto. 1, Col. San Juan de Aragón VI sección, C.P. 07918, Alcaldía Gustavo A. Madero, Ciudad de México.
                </p>
                <hr color="#292563" />
                <h2 className="text-center">
                  Datos Personales:
                </h2>
                <p className="text-justify">
                  En caso de ser <strong> Cliente </strong> con el fin de proveer o contratar los servicios de consultoría y asesoría en materia de servicios en sitio, procedimientos administrativos, financieros y contables, compra de equipo, informarle sobre cambios en nuestros servicios, precios, disponibilidad y condiciones de pago de los mismos, establecer contacto telefónico, envío de información por medios electrónicos acerca de nuestros servicios, evaluar la calidad del servicio que le brindamos y prestar servicios de atención al Cliente.
                </p>
                <p className="text-justify">
                  Si es usted <strong> Proveedor </strong> para contratar y promover los servicios de consultoría y asesoría en materia de servicios en sitio, procedimientos administrativos, financieros y contables, compra de equipo, informarle sobre cambios en nuestros servicios, precios, disponibilidad y condiciones de pago de los mismos, realizar consultas  acerca de los servicios y productos que  usted comercializa, obtener cotizaciones, enviar pedidos, y efectuar pagos por sus servicios o productos, verificación de sus datos y obtención de referencias comerciales y adquirir, comprar, y vender los suministros necesarios para la prestación de los servicios antes descritos.
                </p>
                <p align="text-justify">
                  Si usted es <strong> Empleado </strong> para realizar actividades de selección y contratación de personal, verificar sus antecedentes laborales y académicos, incluir en expediente laboral y para inscribirlo en los registros que corresponden, de conformidad con la legislación laboral, fiscal y de seguridad social aplicable.
                </p>
                <p align="text-justify">
                  <strong> Finalidades no necesarias: </strong> Al proporcionar sus datos, usted autoriza a la Firma “OCA Consultoría en T.I.” su utilización con fines mercadotécnicos, estadísticos, promocionales, publicitarios, informativos o de prospección comercial respecto a actividades y los servicios prestados por la firma, sus filiales, subsidiarias y/o socios de negocios.  Para mayor información acerca del tratamiento y de los derechos que puede hacer valer, usted puede tener acceso al aviso de privacidad completo a través de la página <a href="https://ocaconsultoria.mx/" target="_blank"> www.ocaconsultoria.mx </a> o solicitándolo en el Departamento de Datos Personales de la Firma “OCA Consultoría en T.I.” en el domicilio de Av. Francisco Morazán No. 81, 2do piso, Depto. 1, Col. San Juan de Aragón VI sección, C.P. 07918, Alcaldía Gustavo A. Madero, Ciudad de México, o al correo electrónico avisodeprivacidad@ocaconsultoria.mx.
                </p>
                {/* <p ClassName="footer">
                  OCA Consultoría en T.I. © 2020
                </p> */}
              </CardBody>
            </Card>
          </CardDeck>
        </center>

        <center>
          <div class="col col-10">
            <br />
            <br />
            <div className="card-body">
              {/* <img src={logo} width="40%" className="imafiA1" ></img>
              <h2 class=" text-center ">Aviso de Privacidad</h2>
              <p className="text-justify">
                Este informe se ha realizado tomando en consideración los informes, papeles, y demás registros que el cliente nos ha proporcionado para realizar nuestra labor. Dicha información es única y exclusivamente responsabilidad de la administración del cliente, y nuestra responsabilidad como profesionales especialistas en la materia, se circunscribe a emitir una opinión del tratamiento fiscal o contable respecto de los documentos, papeles e informes por el cliente ofrecidos.
              </p>
              <p className="text-justify">
                La opinión contenida en el presente informe constituye únicamente una interpretación unilateral de los temas aquí tratados, por lo tanto, de ninguna forma se ubica en las hipótesis previstas en las fracciones I, II y III del artículo 89 del Código Fiscal de la Federación, y la presente no pretende contravenir las disposiciones e interpretaciones que pueden ser diversas a los criterios dados a conocer por las autoridades fiscales.
              </p>
              <p className="text-justify">
                El presente correo electrónico contempla la ética profesional del que lo suscribe y en apego a las diversas disposiciones fiscalas aplicables en México, por lo que el emisor del presente correo no se encuentra en los supuestos marcados en el artículo 95 del Código Fiscal de la Federación.
              </p>
              <hr color="#292563" />
              <h2 className="text-center">
                Responsable y Domicilio:
              </h2>
              <p className="text-justify">
                Los datos anteriores son recabados por la Firma “OCA Consultoría en T.I.”, con domicilio en Av. Francisco Morazán No. 81, 2do piso, Depto. 1, Col. San Juan de Aragón VI sección, C.P. 07918, Alcaldía Gustavo A. Madero, Ciudad de México.
              </p>
              <hr color="#292563" />
              <h2 className="text-center">
                Datos Personales:
              </h2>
              <p className="text-justify">
                En caso de ser <strong> Cliente </strong> con el fin de proveer o contratar los servicios de consultoría y asesoría en materia de servicios en sitio, procedimientos administrativos, financieros y contables, compra de equipo, informarle sobre cambios en nuestros servicios, precios, disponibilidad y condiciones de pago de los mismos, establecer contacto telefónico, envío de información por medios electrónicos acerca de nuestros servicios, evaluar la calidad del servicio que le brindamos y prestar servicios de atención al Cliente.
              </p>
              <p className="text-justify">
                Si es usted <strong> Proveedor </strong> para contratar y promover los servicios de consultoría y asesoría en materia de servicios en sitio, procedimientos administrativos, financieros y contables, compra de equipo, informarle sobre cambios en nuestros servicios, precios, disponibilidad y condiciones de pago de los mismos, realizar consultas  acerca de los servicios y productos que  usted comercializa, obtener cotizaciones, enviar pedidos, y efectuar pagos por sus servicios o productos, verificación de sus datos y obtención de referencias comerciales y adquirir, comprar, y vender los suministros necesarios para la prestación de los servicios antes descritos.
              </p>
              <p align="text-justify">
                Si usted es <strong> Empleado </strong> para realizar actividades de selección y contratación de personal, verificar sus antecedentes laborales y académicos, incluir en expediente laboral y para inscribirlo en los registros que corresponden, de conformidad con la legislación laboral, fiscal y de seguridad social aplicable.
              </p>
              <p align="text-justify">
                <strong> Finalidades no necesarias: </strong> Al proporcionar sus datos, usted autoriza a la Firma “OCA Consultoría en T.I.” su utilización con fines mercadotécnicos, estadísticos, promocionales, publicitarios, informativos o de prospección comercial respecto a actividades y los servicios prestados por la firma, sus filiales, subsidiarias y/o socios de negocios.  Para mayor información acerca del tratamiento y de los derechos que puede hacer valer, usted puede tener acceso al aviso de privacidad completo a través de la página <a href="https://ocaconsultoria.mx/"> www.ocaconsultoria.mx </a> o solicitándolo en el Departamento de Datos Personales de la Firma “OCA Consultoría en T.I.” en el domicilio de Av. Francisco Morazán No. 81, 2do piso, Depto. 1, Col. San Juan de Aragón VI sección, C.P. 07918, Alcaldía Gustavo A. Madero, Ciudad de México, o al correo electrónico avisodeprivacidad@ocaconsultoria.mx.
              </p> */}
              <p ClassName="footer">
               <strong> OCA Consultoría en T.I. © 2023</strong>
              </p>
            </div>
          </div>
          {/* <img src={logo} width="10%" className="imafi" ></img>
        <img src={Bot} width="10%" className="imafi4" onClick={this.abrirModal} ></img> */}

        </center>


      </>

    )

  }

}

export default AvisoP;
import React from 'react';
import Menu from '../Menu';
import Footer from '../Footer'
import { Link } from 'react-router-dom';
import { Modal, FormGroup, Label, Input, Button, Jumbotron, Container } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitterSquare, faFacebook, faInstagram, faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import TeamVi1 from '../../IMG/TeamViewer/Team.jpg';
import TeamVi2 from '../../IMG/TeamViewer/TeamViewer.png';
import Que from '../../IMG/OCA/Que.jpg';
import Ver from '../../IMG/OCA/Ver.jpg';
import '../../Component/styles/Contacto.css'
import { Reveal, Tween } from 'react-gsap';
import ReactGa from 'react-ga';
import FormularioSNT from '../Formulario/Conexion';
import EmailFormu from './FormulaEMAIL';
import SucursalesOCA from '../../IMG/Contactanos/HorariosOCA.png';



export default class Nuevacon extends React.Component {


  constructor(props) {
    super(props);
    this.state = {
      name: '',
      apellido: '',
      Contactanos: true
    }
    this.toggle = this.toggle.bind(this)
  }
  toggle() {
    this.setState({
      Contactanos: !this.state.Contactanos
    });
  }

  handleChange = (e) => {
    const { value } = e.target
    this.setState({ name: value })
  }

  handleChange2 = (e) => {
    const { value } = e.target
    this.setState({ apellido: value })
  }

  componentDidMount = async () => {
    ReactGa.initialize('UA-258737016-1');
    ReactGa.pageview('Contacto');
  }

  render() {

   


    const { name } = this.state
    const { apellido } = this.state

    return (
      <>
        <Menu />
        <Modal class="modal fade" isOpen={this.state.Contactanos} toggle={this.toggle} size="" style={{ maxWidth: '800px', width: '100%' }}
          // aria-labelledby="example-modal-sizes-title-sm" centered 
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >

          <button type="button" className="close US2" aria-label="Close" onClick={this.toggle} style={{ backgroundColor: 'steelblue' }}>
            <span aria-hidden="true" style={{ color: 'blue' }}>&times;</span>
          </button>
          {/* <center style={{ backgroundColor: '#B2B6CF' }}> */}
          <center className="LinearSistemas">
            {/* <video src={SiigoNube} width="100%" loop controls muted autoplay="true" preload="auto"></video> */}
            <img src={SucursalesOCA} width="100%" className="p-0 m-0" />
            {/* <h3>Siigo Nube - Sistema Administrativo y Contable </h3> */}
          </center>
        </Modal>
        <p className="pb-5"></p>
        <p className="pb-3"></p>

        <Jumbotron fluid >
          <Container >
            <h1 className="display-3">Contáctanos</h1>
            <p className="lead p-Contacta">Es muy fácil, déjanos tus datos y nos comunicaremos contigo, lo más pronto posible.</p>
          </Container>
        </Jumbotron>
        <hr className="lineaHorizontalSNT" />
        <hr />
        <div style={{ marginTop: '-18px' }}>
          <EmailFormu />
        </div>

        <div className="container">
          <div className=" mt-3">
          </div>
          {/* <FormularioSNT /> */}

          <br />
          <hr color="#292563" />
          {/* Ubicación en el mapa */}
          <Reveal repeat>
            <Tween
              from={{ opacity: -8, rotation: 1 }}
              to={{ opacity: 1, rotation: 0 }}
              duration={1.5}
            >
              <h3 align="center">Ubicanos en el mapa</h3>
            </Tween>
          </Reveal>
          <div className="container" >
            <div class="row">

              <div class="col-sm-12 text-right">
                <Reveal repeat>
                  <Tween
                    from={{ x: '20px', opacity: 0, rotation: 1 }}
                    to={{ x: '0px', opacity: 1, rotation: 0 }}
                    duration={1.5}
                  >
                    <h4 align="center">Matriz</h4>
                  </Tween>
                </Reveal>
                <iframe className="Mapa" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3761.604931795811!2d-99.0705183846831!3d19.472592944445502!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d1fba5566ff367%3A0x52c61dc4aac326b2!2sOca%20Consultor%C3%ADa%20en%20T.I.!5e0!3m2!1ses!2smx!4v1674490161451!5m2!1ses!2smx" allowfullscreen="" width="100%" height="400" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                <br />
                <br />
              </div>
              <div class="col-sm-12 text-right">
                <Reveal repeat>
                  <Tween
                    from={{ x: '-200px', opacity: 0, rotation: 1 }}
                    to={{ x: '0px', opacity: 1, rotation: 0 }}
                    duration={1.5}
                  >
                    {/* <h4 align="center">Matriz</h4> */}
                    <h4 align="center">Sucursal San Ángel</h4>
                  </Tween>
                </Reveal>
                <iframe className="Mapa" src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3764.488951951198!2d-99.19136375083362!3d19.347970048333835!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTnCsDIwJzUyLjciTiA5OcKwMTEnMjEuMCJX!5e0!3m2!1ses!2smx!4v1674864006165!5m2!1ses!2smx" allowfullscreen="" width="100%" height="400" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                {/* <iframe className="Mapa" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3761.604931795811!2d-99.0705183846831!3d19.472592944445502!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d1fba5566ff367%3A0x52c61dc4aac326b2!2sOca%20Consultor%C3%ADa%20en%20T.I.!5e0!3m2!1ses!2smx!4v1674490161451!5m2!1ses!2smx" allowfullscreen="" width="100%" height="400" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
              </div>

              <div class="col-sm-6">
                <Reveal repeat>
                  <Tween
                    from={{ x: '-200px', opacity: 0, rotation: 1 }}
                    to={{ x: '0px', opacity: 1, rotation: 0 }}
                    duration={1.5}
                  >
                    <br />
                    <h4 align="center">Nuevas Sucursales &quot;Querétaro&quot;, &quot;Veracruz&quot;</h4>
                    <img class="" src={Que} width="50%" style={{ display: 'inline' }} />
                  </Tween>
                  <Tween
                    from={{ x: '20px', opacity: 0, rotation: 1 }}
                    to={{ x: '0px', opacity: 1, rotation: 0 }}
                    duration={1.5}
                  >
                    {/* <h4 align="center">Nueva Sucursal Veracruz</h4> */}
                    <img class="" src={Ver} width="50%" />
                  </Tween>
                </Reveal>
              </div>
            </div>
          </div>
          <br />
          <hr color="#292563" />
          {/* Termina Ubicación en el mapa */}
          <Reveal repeat>
            <Tween
              from={{ opacity: -10, rotation: 0 }}
              to={{ opacity: 1, rotation: 0 }}
              duration={1.5}
            >
              {/* <img src={ImaOCA1} className=" AjuSNT IDE1 px-0"></img> */}
              {/* <h1>Contáctanos</h1> */}
              {/* <h2>Es muy fácil, déjanos tus datos y nos comunicaremos contigo, lo mas pronto posible.</h2> */}
              <h3 align="center">Buscanos en redes sociales </h3>
            </Tween>
          </Reveal>
          <div class="text-left px-3 text-center">
            <a href="https://www.facebook.com/ocaconsultoriaenti" target="_blank" style={{ fontSize: '40px' }}>
              <FontAwesomeIcon icon={faFacebook} />
            </a> <p style={{ display: 'inline', position: 'relative', bottom: '8px' }} className="px-1">Facebook</p>
            <a href="https://www.instagram.com/oca.consultoria/" target="_blank" style={{ fontSize: '40px' }}>
              <FontAwesomeIcon icon={faInstagram} />
            </a> <p style={{ display: 'inline', position: 'relative', bottom: '8px' }} className="px-1">Instagram</p>
            <a href="https://wa.me/5644733117" target="_blank" style={{ fontSize: '40px' }}>
              <FontAwesomeIcon icon={faWhatsapp} />
            </a> <p style={{ display: 'inline', position: 'relative', bottom: '8px' }} className="px-1">Whatsapp</p>
          </div>
          <br /><br /><br />
          <hr color="#292563" />
          <div>
            <h3 align="center"> Descarga la aplicación de TeamViewer </h3>
            <p align="center" className="tama821 py-3"> Medio de Comunicación remoto oficial de OCA CONSULTORIA EN T.I. </p>
            <div className="card">
              <div className="body fondoTEAMCON" align="center">
                <img src={TeamVi2} className="" width="50%" height="250px" alt="" />
              </div>
            </div>
            <div className="text-center">
              <a className="btn btn-primary col-4" href="https://download.teamviewer.com/download/TeamViewer_Setup.exe" target="_blank">
                Descargar
              </a>
            </div>
          </div>
        </div >
        {/* </div> */}
        < br />
        <Footer />
      </>
    )
  }
}
import React, { useState, useEffect, useRef } from 'react';
// import React, { useRef } from 'react';
import Menu from '../Menu';
import Footer from '../Footer'
import { Link } from 'react-router-dom';
import { FormGroup, Label, Input, Button, Jumbotron, Container, Modal, ModalBody, ModalFooter, ModalHeader, Card, CardBody, CardHeader, CardFooter } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitterSquare, faFacebook, faInstagram, faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import TeamVi1 from '../../IMG/TeamViewer/Team.jpg';
import TeamVi2 from '../../IMG/TeamViewer/TeamViewer.png';
import Que from '../../IMG/OCA/Que.jpg';
import Ver from '../../IMG/OCA/Ver.jpg';
import './FormulaEMAIL.css';
import { Reveal, Tween } from 'react-gsap';
import ReactGa from 'react-ga';
import FormularioSNT from '../Formulario/Conexion';
import App from '../../App';
import emailjs from '@emailjs/browser';
import EMA from './EMAILOK';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import logo from '../../IMG/Logo/oca-consultoria-logo-ii.png';
import FondoFormularioVideo from '../../IMG/FORMULARIO_C/12.mp4';

export default class Nuevacon extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      name: '',
      apellido: '',
      abrirModalSNT: false,
      ACmodal: false
    }
    this.form = React.createRef();
    this.toggle = this.toggle.bind(this)
  }
  toggle() {
    this.setState({
      ACmodal: !this.state.ACmodal
    });
  }
  abrirModalSNT = () => {
    this.setState({
      abrirModalSNT: true,
    });
  }

  cerrarModalSNT = () => {
    this.setState({ cerrarModalS: !this.state.cerrarModalS });
  }

  handleChange = (e) => {
    const { value } = e.target
    this.setState({ name: value })
  }

  handleChange2 = (e) => {
    const { value } = e.target
    this.setState({ apellido: value })
  }

  componentDidMount = async () => {
    ReactGa.initialize('UA-258737016-1');
    ReactGa.pageview('Contacto');
  }

  handleReset = () => {
    Array.from(document.querySelectorAll("input, textarea")).forEach(
      input => (input.value = ""),
      textarea => (textarea.value = "")
    );
    this.setState({
      itemvalues: [{}]
    });
  };

  sendEmail = (e) => {
    e.preventDefault();
    emailjs.sendForm(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TEMPLATE_ID, this.form.current, process.env.REACT_APP_PUBLIC_KEY).then((result) => {
      this.toggle();
      console.log('Hola: ' + result.text);
      this.handleReset();
    },
      (error) => {
        alert('me...');
        console.log(error.text);
      });
  };
  render() {
    return (
      <>
        <div>
          <Modal isOpen={this.state.ACmodal} toggle={this.toggle}>
            <ModalHeader style={{ textAlign: 'left', backgroundColor: '#F2F2F2' }} toggle={this.toggle}>
              <div className="container">
                <h4 className="text-justify">Datos enviados exitosamente a <strong><i>OCA Consultoría en T.I</i></strong></h4>
                <hr style={{ borderTop: '1px dashed #292563' }} />
                <span style={{ fontSize: '10pt' }}>En un máximo de 24 hrs nos pondremos en contacto con usted.</span>
              </div>
            </ModalHeader>
            <ModalFooter align="center">
              <img src={logo} className="mr-auto" width="30%" alt="logo-OCA"></img>
              <Button style={{ backgroundColor: '#292563' }} onClick={this.toggle}>ACEPTAR</Button>
            </ModalFooter>
          </Modal>
          {/* CARD FORMULARIO */}
          <div>
            <Card >
              <CardHeader className="container FondoDatosContacto">
                <h1 className="display-4 FondoDatosContactoLetra">Datos de Contacto</h1>
              </CardHeader>
              {/* <hr /> */}
              <div className="Nivel1P1">
                {/* <video className="vidFormula821" src={FondoFormularioVideo} loop muted autoplay="true" preload="auto"></video> */}
                <CardBody className="CardFormulario">
                  <form className='cf' ref={this.form} onSubmit={this.sendEmail}>
                    <div className='half left cf'>
                      <input type='text' placeholder='Nombre' name='to_name' minLength={3} maxLength={35} className="inputForm2" required />
                      <input type='text' placeholder='Apellido' name='to_apellido' minLength={3} maxLength={35} className="inputForm2" required />
                      <input type='email' placeholder='Email' name='from_name' pattern="[a-z0-9._%+\-]+@[a-z0-9.\-]+\.[a-z]{2,}$" maxLength={50} className="inputForm2" required />
                      <input type='text' placeholder='Teléfono' name='to_telefono' minLength={10} maxLength={15} className="inputForm2" required />
                    </div>
                    <div className='half right cf'>
                      <textarea name='message' type='text' placeholder='Dudas o Comentarios' minLength={1} maxLength={400} className="inputForm2" required></textarea>
                    </div>
                    <input type='submit' value='Enviar' id='input-submit' />
                    <div className="form-check pt-3 ">
                      <br />

                      <div className="row card AceptoAviso">
                        <label className="form-check-label" for="flexCheckDefault">
                          <div className="col-1  mt-1">
                            <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" required />
                          </div>
                          <div className="col-10 text-left mt-2 ml-4">
                            {/* <p className="pl-1" style={{ width: '180px', marginTop: '2px' }}><b style={{ color: '#00012D' }}><strong>Acepto  <a href="/AvisoP" style={{ color: 'Blue' }}> Aviso de privacidad </a></strong></b></p> */}
                            <p className="" ><b style={{ color: '#00012D' }}><strong>Acepto <a href="/AvisoP" style={{ color: 'Blue' }}> Aviso de privacidad </a></strong></b></p>
                          </div>
                        </label>

                      </div>
                    </div>
                    {/* <input type='button' value='Limpiar formulario' onClick={this.handleReset} /> */}
                  </form>
                </CardBody>
              </div>
              <CardFooter>
                <h5 className="text-justify text-center CardFormulario18">Atenderemos tus dudas y te asesoraremos con la información que necesites. </h5>
              </CardFooter>
            </Card>
          </div>
          {/* <Button color="danger" onClick={this.toggle}></Button> */}
        </div>
      </>
    )
  }
}
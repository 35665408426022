import React from 'react';

//imagenes 
import derecha from '../IMG/Botones/Flecha_derecha.png';
import izquierda from '../IMG/Botones/Flecha_izquierda.png';
import '../Component/styles/CarruselServi2.css';
import Aspel1 from '../IMG/CarruselServicios/ESET.png';
import Aspel2 from '../IMG/CarruselServicios/EsetPa.png';
import Aspel3 from '../IMG/CarruselServicios/sophos_firewall-landing-page-graphic-PhotoRoom.png-PhotoRoom.png';
import Aspel4 from '../IMG/CarruselServicios/Powerful-Performance.png';
import Aspel5 from '../IMG/CarruselServicios/Sophos-UTM-Lisans--238.jpg';
import Aspel6 from '../IMG/CarruselServicios/sophos-xg.jpg';
import {
  Card, CardImg, CardDeck
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { Reveal, Tween } from 'react-gsap';
// import Carrusel2 from '../paginas/CarruselServi2'


class CarruselServi2 extends React.Component {

  render() {

    return (
      <div class="px-0">
        <div id="carouselExampleControls1" className="carousel slide carousel-size" data-ride="carousel">
          <a className="carousel-control-prev" href="#carouselExampleControls1" role="button" data-slide="prev">
            <img src={izquierda} className="col-4 FIzquierda1" style={{backgroundColor:'#0096A2'}} alt="" />
            <span className="sr-only">Previous</span>

          </a>
          <a className="carousel-control-next " href="#carouselExampleControls1" role="button" data-slide="next">
            <img src={derecha} className="col-4 FDerecha1" style={{backgroundColor:'#0096A2'}} alt="" />
            <span className="sr-only">Next</span>
          </a>
          <div className="carousel-inner TamSNT">
            <div className="carousel-item active">
              <div className="container">

                <CardDeck >

                  <Card style={{ backgroundColor: 'transparent', borderColor: 'transparent' }}>
                    <Reveal repeat>
                      <Tween
                        from={{ opacity: -20, rotation: -15 }}
                        to={{ opacity: 10, rotation: 0 }}
                        duration={0.3}
                      >
                        <center>
                          <img src={Aspel1} className="SombraSNTCarru" width="50%" ></img>
                        </center>
                      </Tween>
                    </Reveal>
                  </Card>
                </CardDeck>

              </div>
            </div>
            <div className="carousel-item">
              <div className="container">

                <CardDeck >
                  <Card style={{ backgroundColor: 'transparent', borderColor: 'transparent' }}>
                    <Reveal repeat>
                      <Tween
                        from={{ opacity: -20, rotation: -15 }}
                        to={{ opacity: 10, rotation: 0 }}
                        duration={0.3}
                      >
                        <center>
                          <img src={Aspel2} className="" width="75%" ></img>
                        </center>
                      </Tween>
                    </Reveal>
                  </Card>
                </CardDeck>
              </div>
            </div>
            <div className="carousel-item">
              <div className="container">
                <CardDeck >
                  <Card style={{ backgroundColor: 'transparent', borderColor: 'transparent' }}>
                    <Reveal repeat>
                      <Tween
                        from={{ opacity: -20, rotation: -15 }}
                        to={{ opacity: 10, rotation: 0 }}
                        duration={0.3}
                      >
                        <center>
                          <img src={Aspel3} className="SombraSNTCarru" width="99%" ></img>
                        </center>
                      </Tween>
                    </Reveal>
                  </Card>
                </CardDeck>

              </div>
            </div>

            <div className="carousel-item">
              <div className="container">

                <CardDeck >
                  <Card style={{ backgroundColor: 'transparent', borderColor: 'transparent' }}>
                    <Reveal repeat>
                      <Tween
                        from={{ opacity: -20, rotation: -15 }}
                        to={{ opacity: 10, rotation: 0 }}
                        duration={0.3}
                      >
                        <center>
                          <img src={Aspel4} className="SombraSNTCarru" width="61%" ></img>
                        </center>
                      </Tween>
                    </Reveal>
                  </Card>
                </CardDeck>

              </div>
            </div>

            <div className="carousel-item">
              <div className="container">

                <CardDeck >
                  <Card style={{ backgroundColor: 'transparent', borderColor: 'transparent' }}>
                    <Reveal repeat>
                      <Tween
                        from={{ opacity: -20, rotation: -15 }}
                        to={{ opacity: 10, rotation: 0 }}
                        duration={0.3}
                      >
                        <center>
                          <img src={Aspel5} className="SombraSNTCarru" width="78.8%" ></img>
                        </center>
                      </Tween>
                    </Reveal>
                  </Card>
                </CardDeck>

              </div>
            </div>

            <div className="carousel-item">
              <div className="container">

                <CardDeck >
                  <Card style={{ backgroundColor: 'transparent', borderColor: 'transparent' }}>
                    <Reveal repeat>
                      <Tween
                        from={{ opacity: -20, rotation: 15 }}
                        to={{ opacity: 10, rotation: 0 }}
                        duration={0}
                      >
                        <center>
                          <img src={Aspel6} className="BorderSNT" width="98%" ></img>
                        </center>
                      </Tween>
                    </Reveal>
                  </Card>
                </CardDeck>

              </div>
            </div>

          </div>
        </div>
      </div>

    )
  }
}

export default CarruselServi2;
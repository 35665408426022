import React from 'react';
//imagenes 
import { Carousel } from 'react-bootstrap';
import { Reveal, Tween } from 'react-gsap';
import Cliente1 from '../IMG/OCA/OCAEVENTOS/Imagen2.jpg';
import Cliente2 from '../IMG/OCA/OCAEVENTOS/Imagen4.jpg';
import Cliente3 from '../IMG/OCA/OCAEVENTOS/Imagen5.jpg';
import Cliente4 from '../IMG/OCA/OCAEVENTOS/EVENTO_OCA.png';
import OcaEventos from '../IMG/OCA/OCAEVENTOS/OCA1OCA.jpeg'
import OcaEventos2 from '../IMG/OCA/OCAEVENTOS/OCA1OCA2.jpeg'
import OcaEventos3 from '../IMG/OCA/OCAEVENTOS/OCA1OCA3.jpeg'
import OcaEventos4 from '../IMG/OCA/OCAEVENTOS/OCA1OCA4.jpeg'
import OcaEventos5 from '../IMG/OCA/OCAEVENTOS/OCA1OCA5.jpeg'
import OcaEventos6 from '../IMG/OCA/OCAEVENTOS/OCA1OCA6.jpeg'
import '../Component/styles/CarruselClientes.css';

import {
    Card, Button, CardImg, CardTitle, CardText, CardDeck,
    CardSubtitle, CardBody, CardFooter, CardHeader
} from 'reactstrap';
//Aqui mandamos a llmar nuestros estilos para hacer el cambio de flechas - slider

class CarruselClientes extends React.Component {
    // Carrusel CLientes
    render() {
        return (
            <>
                <div>
                    <div className='container-fluid' >
                        <div className="row">
                            <div className="col-12">
                                <Carousel>    
                                        {/* Carrusel segunda parte */}
                                        {/* (1) */}
                                    <Carousel.Item>
                                        <CardDeck className="SombraCARDSNT pb-3">
                                            <Card style={{ backgroundColor: '#eff3fb' }} className="pb-2">
                                                <div className="contenedor1">
                                                    <center>
                                                            <img src={Cliente1} width="100%" height="380" className="Opaci" ></img>
                                                    </center>
                                                    <CardFooter className="text-center ScrollCardClientes">
                                                    <span className="contenedor3">"OCA Consultoría en T.I."</span>
                                                    </CardFooter>
                                                </div>
                                            </Card>
                                            
                                            <Card style={{ backgroundColor: '#eff3fb' }} className="pb-2">
                                                <div className="contenedor1">
                                                    <center>
                                                            <img src={Cliente4} width="100%" height="380" className="Opaci" ></img>
                                                    </center>
                                                    <CardFooter className="text-center ScrollCardClientes">
                                                        {/* <span className="contenedor3">OCA Consultoría en T.I. empresa especializada en tecnología de la ​información y consultoría.</span> */}
                                                        <span className="contenedor3">"El éxito necesita administrarse".</span>
                                                    </CardFooter>
                                                </div>
                                            </Card>
                                        </CardDeck>
                                    </Carousel.Item>


                                    {/* (2) */}
                                    <Carousel.Item>
                                        <CardDeck className="SombraCARDSNT pb-3">
                                            <Card style={{ backgroundColor: '#eff3fb' }} className="pb-2">
                                                <div className="contenedor1">
                                                    <center>
                                                            <img src={OcaEventos3} width="100%" height="380" className="Opaci" ></img>
                                                    </center>
                                                    <CardFooter className="text-center ScrollCardClientes">
                                                    <span className="contenedor3">"OCA Consultoría en T.I." </span>
                                                    </CardFooter>
                                                </div>
                                            </Card>
                                            
                                            <Card style={{ backgroundColor: '#eff3fb' }} className="pb-2">
                                                <div className="contenedor1">
                                                    <center>
                                                            <img src={OcaEventos4} width="100%" height="380" className="Opaci" ></img>
                                                    </center>
                                                    <CardFooter className="text-center ScrollCardClientes">
                                                        {/* <span className="contenedor3">OCA Consultoría en T.I. empresa especializada en tecnología de la ​información y consultoría.</span> */}
                                                        <span className="contenedor3">"El éxito necesita administrarse".</span>
                                                    </CardFooter>
                                                </div>
                                            </Card>
                                        </CardDeck>
                                    </Carousel.Item>

                                    {/* (3) */}
                                    <Carousel.Item>
                                        <CardDeck className="SombraCARDSNT pb-3">

                                            <Card style={{ backgroundColor: '#eff3fb' }} className="pb-2">
                                                <div className="contenedor1">
                                                    <center>
                                                            <img src={Cliente2} width="100%" height="380" className="Opaci" ></img>
                                                    </center>
                                                    <CardFooter className="text-center ScrollCardClientes">
                                                        <span className="contenedor3">"Nuestro objetivo es brindar un servicio de calidad y profesionalismo.</span>
                                                    </CardFooter>
                                                </div>
                                            </Card>
                                            <Card style={{ backgroundColor: '#eff3fb' }} className="pb-2">
                                                <div className="contenedor1">
                                                    <center>
                                                            <img src={Cliente3} width="100%" height="380" className="Opaci" ></img>
                                                    </center>
                                                    <CardFooter className="text-center ScrollCardClientes">
                                                        <span className="contenedor3">"Con Siigo|Aspel controla todas las operaciones de tu empresa".</span>
                                                    </CardFooter>
                                                </div>
                                            </Card>
                                        </CardDeck>
                                    </Carousel.Item>

                                    {/* (4) */}
                                    <Carousel.Item>
                                        <CardDeck className="SombraCARDSNT pb-3">
                                            <Card style={{ backgroundColor: '#eff3fb' }} className="pb-2">
                                                <div className="contenedor1">
                                                    <center>
                                                            <img src={OcaEventos5} width="100%" height="380" className="Opaci" ></img>
                                                    </center>
                                                    <CardFooter className="text-center ScrollCardClientes">
                                                    <span className="contenedor3">"OCA Consultoría en T.I." </span>
                                                    </CardFooter>
                                                </div>
                                            </Card>
                                            
                                            <Card style={{ backgroundColor: '#eff3fb' }} className="pb-2">
                                                <div className="contenedor1">
                                                    <center>
                                                            <img src={OcaEventos6} width="100%" height="380" className="Opaci" ></img>
                                                    </center>
                                                    <CardFooter className="text-center ScrollCardClientes">
                                                        {/* <span className="contenedor3">OCA Consultoría en T.I. empresa especializada en tecnología de la ​información y consultoría.</span> */}
                                                        <span className="contenedor3">"El éxito necesita administrarse".</span>
                                                    </CardFooter>
                                                </div>
                                            </Card>
                                        </CardDeck>
                                    </Carousel.Item>

                                    {/* (5) */}
                                    <Carousel.Item>
                                        <CardDeck className="SombraCARDSNT pb-3">
                                            <Card style={{ backgroundColor: '#eff3fb' }} className="pb-2">
                                                <div className="contenedor1">
                                                    <center>
                                                            <img src={Cliente1} width="100%" height="380" className="Opaci" ></img>
                                                    </center>
                                                    <CardFooter className="text-center ScrollCardClientes">
                                                        <span className="contenedor3">"Soluciones a la medida, OCA Consultoría en T.I.". &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;∴</span>
                                                    </CardFooter>
                                                </div>
                                            </Card>
                                            <Card style={{ backgroundColor: '#eff3fb' }} className="pb-2">
                                                <div className="contenedor1">
                                                    <center>
                                                            <img src={Cliente3} width="100%" height="380" className="Opaci" ></img>
                                                    </center>
                                                    <CardFooter className="text-center ScrollCardClientes">
                                                        <span className="contenedor3">†Más de 1,000 implementaciones exitosas.&nbsp;</span>
                                                    </CardFooter>
                                                </div>
                                            </Card>
                                        </CardDeck>
                                        {/* <Carousel.Caption>
                                            <h3>Third slide label</h3>
                                            <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>
                                        </Carousel.Caption> */}
                                    </Carousel.Item>

                                </Carousel>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default CarruselClientes; 
import React from 'react';
import logo from '../IMG/Logo/logo.png';
import LogoOCA from '../IMG/Logo/oca-consultoria-logo-ii.png';
import LogoAspel from '../IMG/LogosAspel/LogoAspel.png';
import IG from '../IMG/footer/IG.png'
import TW from '../IMG/footer/TW.png'
import GO from '../IMG/footer/GO.png'
import FB from '../IMG/footer/FB.png'
import '../Component/styles/Footer.css';


class Footer extends React.Component {

  render() {

    return (
      <div className="card container-fluid fondFoote" >
        <footer id="myFooter" className=" py-4 fotcol " >


          <center>
            <div className="containerSNT">
              <div class="row">


                <div class="card col-sm-12 border-0" style={{ backgroundColor: '#eff3fb' }}>
                  <div>
                    <div class="card-body ">
                      <div class="card border-0 d-flex justify-content-between align-items-center" style={{ backgroundColor: 'transparent', borderColor: 'transparent' }}>
                        <h5 align="center" style={{ fontSize: '14pt' }}><strong>La mejor manera de predecir el futuro es crearlo.
                          Iniciemos tu proyecto. </strong></h5>
                      </div>
                    </div>
                  </div>
                </div>
                <br /><br /><br /><br />

                <div class="card border-0 bg-light StyleSNT col-12 col-sm-6 col-lg-3 col-xl-3 col-xxl-3">
                  <div>
                    <div className="card-body">
                      <div class="card border-0 d-flex justify-content-between align-items-center" style={{ backgroundColor: 'transparent', borderColor: 'transparent' }}>
                        <a href="https://www.ocaconsultoria.mx/" className=""><img src={LogoOCA} width="100%" height="120" style={{ marginTop: '-20%' }}></img></a>
                        <a href="https://www.ocaconsultoria.mx/" className="ml-5 py-3"><img src={LogoAspel} width="100%" height="120" style={{ marginBottom: '0%' }} className="imgSNT"></img></a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card border-0 bg-light StyleSNT col-12 col-sm-6 col-lg-3 col-xl-3 col-xxl-3">
                  <div class="card-body" style={{lineHeight:'1.5em'}}>

                    <h5 align="center" style={{ fontSize: '14pt' }}><strong> Informes </strong></h5>
                    <p align="center">

                      informes@ocaconsultoria.mx
                    </p>
                    <p align="center">
                      Telefonos: <br />
                      Tel. 55-2158-5639 <br />
                      Tel. 55-2158-2590
                    </p>
                  </div>
                </div>
                <div class="card border-0 bg-light StyleSNT col-12 col-sm-6 col-lg-3 col-xl-3 col-xxl-3">
                  <div class="card-body" style={{lineHeight:'1.5em'}}>
                    <h5 align="center" style={{ fontSize: '14pt' }}><strong> Horarios </strong></h5>
                    <p align="center">
                      Lunes a Viernes
                    </p>
                    <p align="center">
                      09:00 am - 06:00 pm
                    </p>
                  </div>
                </div>
                <div class="card border-0 bg-light StyleSNT col-12 col-sm-6 col-lg-3 col-xl-3 col-xxl-3">
                  <div class="card-body">
                    <h5 style={{ fontSize: '14pt', lineHeight:'1.5em'}}><strong> NUESTRAS REDES SOCIALES </strong></h5>
                    <br />
                    <div className="row justify-content-between ">
                      &nbsp;&nbsp;&nbsp;<a href="https://www.instagram.com/oca.consultoria/" target="_blank"><img src={IG} height="30px" width="30px" ></img></a>&nbsp;&nbsp;&nbsp;
                      <a href="https://www.facebook.com/ocaconsultoriaenti" target="_blank"><img src={FB} height="30px" width="30px"></img></a> &nbsp;&nbsp;&nbsp;
                      {/* <a href=""><img src={TW}height="30px" width="30px"></img></a>&nbsp;&nbsp;&nbsp; */}
                      <a href="https://www.ocaconsultoria.mx/" target="_blank"><img src={GO} height="30px" width="30px"></img></a>&nbsp;&nbsp;&nbsp;
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </center>

          <div class="footer-copyright container">

            <div class="row">

              <div class="col-sm-7">
                <p >© 2024 ​OCA Consultoría en T.I.</p>
              </div>

              <div class="col-sm-5">
                <p > <a href="/AvisoP"> Aviso de privacidad </a> Consulta términos y condiciones.</p>
              </div>
            </div>
          </div>
          <br />
          <div class="footer-copyright container">
            <p align="center" className="let1">La marca se encuentra registrada ante el Instituto Mexicano de la Propiedad Industrial, por lo que se prohíbe su uso no autorizado.</p>
          </div>
        </footer >
      </div>
    )

  }
}

export default Footer;